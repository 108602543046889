<script>
  import { XIcon } from 'svelte-feather-icons';
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
</script>

<style>

</style>

<button class="icon-button" on:click="{e => dispatch('click', e)}">
  <XIcon size="18" />
</button>
