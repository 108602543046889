import { tsv, autoType, json } from 'd3';
import {
  flatten,
  mapValues,
  groupBy,
  keyBy,
  fromPairs,
  zip,
  findIndex,
} from 'lodash-es';
import { readable, writable, derived } from 'svelte/store';
import { isPastElectionYear } from '../config.js';

const data_version = '2020-11-02';

const yearlyTrendsURL = './data/yearly_' + data_version + '.tsv';
const monthlyTrendsURL = './data/monthly_' + data_version + '.tsv';
const monthlyTrendsURLInit = './data/monthly_init_' + data_version + '.tsv';
const topicsURL = './data/topics_' + data_version + '.tsv';
// const electionResultsURL = './data/election_results.tsv';
const statesURL = './data/states.tsv';

// "topic_id", "topic_label", "geo", "date","value"
// topic value geo	date
export const yearlyTrendsDataSet = readable([], set => {
  Promise.all([tsv(yearlyTrendsURL)]).then(([_rows]) => {
    set(
      // topic_id	topic_label	geo	2004	2005	2006	2007	2008	2009	2010	2011	2012	2013	2014	2015	2016	2017	2018	2019	2020
      flatten(
        _rows.map(
          ({ topic_id, geo, ...year_values }) =>
            Object.keys(year_values).map(dateString => ({
              id: topic_id,
              value: +year_values[dateString],
              normalizedValue: +year_values[dateString],
              geo,
              dateString,
              isPastElectionYear: isPastElectionYear(dateString),
              date: new Date(dateString),
            })),
          // TODO: add normalized score
        ),
      ),
    );
  });
});

export const monthlyTrendsDataSet = readable([], async set => {
  for (const url of [monthlyTrendsURLInit, monthlyTrendsURL]) {
    await Promise.all([tsv(url)]).then(async ([_rows]) => {
      // wait till animation stops
      if (window.animatingAt) {
        while (performance.now() < window.animatingAt + 200) {
          await new Promise(s => setTimeout(s, 200));
        }
      }
      const a = performance.now();

      const months = _rows.columns.slice(2).map(dateString => {
        const sp = dateString.split('-');
        return {
          dateString,
          year: +sp[0],
          month: +sp[1],
          isElYear: isPastElectionYear(+sp[0]),
        };
      });
      const values = Array((_rows.columns.length - 3) * _rows.length);

      let i = 0;
      for (const r of _rows) {
        const id = r.topic_id;

        for (const m of months) {
          values[i++] = {
            id,
            value: +r[m.dateString],
            normalizedValue: +r[m.dateString],
            year: m.year,
            month: m.month,
            dateString: m.dateString,
            geo: r.geo,
            isPastElectionYear: m.isElYear,
          };
        }
      }

      set(values);
      // console.log(a - performance.now());
    });
    // await new Promise(f => setTimeout(f, 2000))
  }
});

export const topicsDataSet = readable([], set => {
  Promise.all([tsv(topicsURL)]).then(([rows]) => {
    set(rows);
  });
});

export const statesDataSet = readable([], set => {
  Promise.all([tsv(statesURL)]).then(([_rows]) => {
    set(
      _rows.map(d => ({
        id: d.id,
        label: d.label,
        shortLabel: d.short_label,
      })),
    );
  });
});

// state_id	year	democrat	republican	diff
// export const electionResultsDataSet = readable([], set => {
//   Promise.all([tsv(electionResultsURL, autoType)]).then(([_rows]) => {
//     set(_rows);
//   });
// });
