<script>
  import { scaleOrdinal } from 'd3';
  import { orderBy } from 'lodash-es';

  import { getContext } from 'svelte';
  import breakPoint from '../state/BreakPoint.js';
  import { electionYears, breakPoints } from './../config.js';
  import Legend from '../components/Legend.svelte';
  import Grid from '../components/Grid.svelte';
  import ShowMoreButton from '../components/ShowMoreButton.svelte';
  import AnimatedLineChart from '../components/AnimatedLineChart.svelte';

  import {
    yearColors,
    greyYearColorScale,
    electionYearColors,
  } from './../util/colors.js';

  const { selectedStateId } = getContext('stores');

  let showMore = false;
  export let specificYearTrendingTopics = [];
  export let selectedState = {};
  export let referenceYear;
  export let stateSentenceFragment;
  export let embedVersion = false;
  export let states = [];
  let showFallOnly = false;
  let visible;

  // const breakPoint = getContext('stores');

  $: numTopics = (showMore
    ? scaleOrdinal()
        .domain(Object.keys(breakPoints))
        .range([6, 8, 9, 12, 18, 18])
    : scaleOrdinal()
        .domain(Object.keys(breakPoints))
        .range([3, 4, 6, 6, 8, 8]))($breakPoint);

  $: displayedTopics = specificYearTrendingTopics.slice(0, numTopics);
</script>

<style lang="scss">
  .value-legend {
    display: flex;
    align-items: center;
    justify-items: center;
  }

  .chart-container {
    @include fluid-space(padding-top, $sp-medium);
    @include fluid-space(padding-bottom, $sp-medium);
  }

  .comment {
    padding-right: 0.5em;
    color: $c-gray-3;
  }

  .controls-with-state {
    display: flex;

    select {
      margin: 0 0 0 1em;
      height: 2.5em;
      display: inline-block;
      border-radius: 5px;
    }

    @media (max-width: 500px) {
      flex-direction: column;
      select {
        margin: 1em 0;
      }
    }
  }
</style>

<Legend>
  <div slot="description">
    <strong>Political search terms</strong>
    with especially high search interest {stateSentenceFragment} in {referenceYear}
  </div>

  <div slot="controls" class="value-legend column-on-mobile">

    {#if embedVersion}
      <div class="controls-with-state">
        <div class="tabs">
          {#each electionYears as year}
            <button
              on:click="{() => (referenceYear = year)}"
              class:active="{year == referenceYear}">
              {year}
            </button>
          {/each}
        </div>
        <select bind:value="{$selectedStateId}" style="--color: #777;">
          <option value="US">US</option>
          <option>----</option>
          {#each orderBy(states, 'label') as { label, id }}
            <option value="{id}">{label}</option>
          {/each}
        </select>
      </div>
    {:else}
      <div class="tabs">
        {#each electionYears as year}
          <button
            on:click="{() => (referenceYear = year)}"
            class:active="{year == referenceYear}">
            {year}
          </button>
        {/each}
      </div>
    {/if}

    <!-- <div style="color:{greyYearColorScale(2012)}">Other years</div> -->
  </div>

  <!-- <div slot="controls">
    <div>
      
      <label>
        <input type="radio" bind:group="{showFallOnly}" value="{false}" />
        Full year
      </label>

      <label>
        <input type="radio" bind:group="{showFallOnly}" value="{true}" />
        Election season
      </label>
    </div>
  </div> -->

</Legend>

<div
  style="--color: {referenceYear !== 2020 ? yearColors[referenceYear - 2004] : 'inherit'}"
  class="chart-container">
  <Grid container gutter="{20}">

    {#each displayedTopics as { topic, monthlyData }, i ([selectedState.idd, referenceYear, topic.id].toString())}
      <Grid
        xs="{12}"
        sm="{6}"
        md="{showMore ? 4 : 6}"
        lg="{showMore ? 3 : 4}"
        xl="{showMore ? 2 : 3}">
        <!-- {specificYearTrendScore} -->
        <AnimatedLineChart
          {topic}
          {referenceYear}
          {showFallOnly}
          values="{monthlyData}" />
      </Grid>
    {/each}

  </Grid>
</div>
{#if !embedVersion}
  <div class:hide-on-mobile="{showMore}">
    <ShowMoreButton bind:showMore />
  </div>
{/if}
