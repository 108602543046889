<script>
  import { formatValue } from './../util/formatting.js';
  import { scaleLinear, quantile } from 'd3';
  import { orderBy } from 'lodash-es';
  // Import the getContext function from svelte
  import { getContext } from 'svelte';
  import { spring, tweened } from 'svelte/motion';
  import { sineInOut } from 'svelte/easing';
  import { purple } from '../util/colors.js';

  // Access the context using the 'LayerCake' keyword
  // Grab some helpful functions
  const { data, xScale, yScale, xGet, yGet, rGet, width } = getContext(
    'LayerCake',
  );
  const { hoveredItem, selectedStateId } = getContext('stores');

  $: nodes = $data;

  $: quantileThreshold = orderBy(
    nodes,
    'deviationFromBaselineScore',
    'desc',
  )[10].deviationFromBaselineScore;

  $: labelScale = d =>
    $selectedStateId === d.data.id ? 1 : Math.sqrt($rGet(d) + 0.01) || 1;
  $: opacity = d =>
    $selectedStateId === d.data.id ? 1 : $rGet(d) + 0.001 || 0.5;

  $: showScore = d =>
    (d.deviationFromBaselineScore >= quantileThreshold &&
      d.deviationFromBaselineScore >= 1.1) ||
    $selectedStateId === d.data.id;
</script>

<style lang="scss">
  .el {
    @include bold;
    position: absolute;
    cursor: pointer;
    > div {
      > div {
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .score,
    .label {
      transition: transform 0.3s ease-in-out, color 0.3s ease-in-out,
        opacity 0.3s ease-in-out;
    }
    .score {
      @include number;
      color: $purple;
      font-size: 2em;
      // @include text-shadow;
      font-weight: 300;
      opacity: 0.8;
      mix-blend-mode: multiply;
    }
    .label {
      color: $purple;
      width: 5em;
      mix-blend-mode: multiply;
      line-height: 1.25;
    }
  }
</style>

<div style="position: relative;">

  {#each nodes as d, i (d.id)}
    <div
      class="el"
      style="font-size: {3.5 * Math.sqrt($width / 100)}px; left:{$xGet(d)}px;
      top:{$yGet(d)}px;">

      <div
        style="opacity:{opacity(d)}; transform: scale({labelScale(d)});"
        on:click="{() => ($selectedStateId = $selectedStateId === d.data.id ? 'US' : d.data.id)}">
        <!-- on:mouseover="{() => showTooltip(d)}"
        on:mouseout="{() => showTooltip()}" -->

        <div
          style="transform: translate(-50%, -92%) scale({showScore(d) ? 1 : 0});"
          class="score number">
          {formatValue(d.deviationFromBaselineScore, 'electionYearTrendScore')}
        </div>

        <div
          style="transform: translate(-50%, {showScore(d) ? 0 : -50}%);"
          class="label">
          {d.data.label.replace('District of', 'District of')}
        </div>
      </div>

      <!-- on:mouseover="{() => ($hoveredItem = { state: d.data })}"
            on:mouseout="{() => ($hoveredItem = null)}" -->
    </div>
  {/each}

</div>
