<script>
  import { formatValue } from './../util/formatting.js';
  import YearAxis from './YearAxis.svelte';

  import { getContext } from 'svelte';
  import { electionYears, years } from '../config.js';

  import {
    scaleLinear,
    scaleTime,
    line,
    extent,
    curveMonotoneX,
    clientPoint,
    max,
  } from 'd3';

  import { Delaunay } from 'd3-delaunay';

  import { uniq, flatten, range } from 'lodash-es';

  const { selectedStateId, modeId, hoveredItemId } = getContext('stores');
  import { categoryColors, blend, yearColors } from '../util/colors.js';

  let localHoveredItemId;
  let clientWidth, clientHeight;

  const { hoveredItem } = getContext('stores');

  export let colorMode = 'election-year';
  export let fitInContainer = false;
  export let topic = {};
  export let states = [];
  export let stateValues = [];
  export let large = false;
  export let inverted = false;
  export let onYearSelected;

  $: W = clientWidth || 0;
  $: H =
    (fitInContainer ? Math.max(20, clientHeight) : clientWidth * 0.25) || 0;
  $: SIZE = scaleLinear()
    .domain([300, 1200])
    .range([1, 2])
    .clamp(true)(clientWidth);

  $: showChart = H > 50;

  const padding = { left: 0, right: 0, top: 0, bottom: 0 };

  $: dates = uniq(flatten(Object.values(stateValues)).map(d => d.date));
  $: dateExtent = extent(dates);

  $: xScale = scaleTime()
    .domain(dateExtent)
    .range([0, W]);

  $: xF = d => xScale(d.date);

  $: valueMax = max(
    flatten(Object.values(stateValues)).filter(d => d.geo !== 'DC'),
    d => d.value,
  );

  $: yF = d =>
    scaleLinear()
      .domain([0, valueMax])
      .range([H - 20, 0])(d.value);

  $: path = line()
    .x(xF)
    .y(yF)
    .curve(curveMonotoneX);

  $: displayItems = showChart
    ? states.concat([{ id: 'US', label: 'US' }]).map(d => ({
        id: d.id,
        state: d,
        values: stateValues[d.id],
        path: path(stateValues[d.id] || []),
      }))
    : [];

  $: hoveredItems = displayItems.filter(d => d.id === localHoveredItemId);

  $: selectedItems = displayItems.filter(
    d => d.id === ($selectedStateId ? $selectedStateId : 'US'),
  );

  const moveHandler = event => {
    const hoverDistance = 25;
    const sample = 100;
    const parent = event.currentTarget.parentElement;
    const paths = Array.from(parent.querySelectorAll('path')).slice(
      0,
      displayItems.length,
    );
    if (!parent.delaunay || parent.delaunay.displayItems !== displayItems) {
      const points = paths
        .map(p => {
          const l = p.getTotalLength();
          return range(sample).map(i =>
            p.getPointAtLength((i / (sample - 1)) * l),
          );
        })
        .flat();
      parent.delaunay = Delaunay.from(points, d => d.x, d => d.y);
      parent.delaunay.displayItems = displayItems;
    }
    const point = clientPoint(parent, event);
    const p_i = parent.delaunay.find(...point);
    const delta = Math.hypot(
      point[0] - parent.delaunay.points[2 * p_i],
      point[1] - parent.delaunay.points[2 * p_i + 1],
    );
    if (delta > hoverDistance) {
      localHoveredItemId = $hoveredItemId = null;
      $hoveredItem = null;
    } else {
      const i = Math.floor(p_i / sample);
      const d = displayItems[i];

      if (d) {
        const yearIndex = Math.min(
          16,
          Math.max(0, Math.round((17 * event.offsetX) / clientWidth)),
        );

        hoveredItem.set({
          id: d.id,
          state: d.state,
          value: d.values[yearIndex].normalizedValue,
          // year: 2004 + yearIndex,
          comment:
            formatValue(
              d.values[yearIndex].normalizedValue /
                stateValues['US'][yearIndex].normalizedValue,
              'electionYearTrendScore',
            ) +
            ' compared to US search interest in ' +
            (2004 + yearIndex),
          topic,
        });
        localHoveredItemId = $hoveredItemId = d.id;
      }
    }
  };
</script>

<style lang="scss">
  .container {
    width: 100%;
    height: 100%;
    // @include fluid-space(margin-bottom, $fs-base);

    &.inverted {
      background: transparent !important;
    }
  }
  svg {
    width: 100%;
    height: auto;
    overflow: visible;

    // @include fluid-space(margin-top, $fs-base);
  }

  path {
    fill: none;
  }

  :not(.inverted) path {
    stroke: url(#electionYearColor);
    mix-blend-mode: multiply;
  }

  path.bg {
    stroke-width: 6;
    stroke: #fff;
    opacity: 1;
    mix-blend-mode: normal;
  }

  path.fg {
    stroke-width: 10px;
    stroke: #000;
    opacity: 0.25;
  }

  path.selected {
    stroke-width: 1.5;
    opacity: 1;
    stroke: #000;
  }

  path.hovered {
    stroke-width: 1.5;
    opacity: 1;
    stroke: #000;
  }

  .inverted {
    path.fg,
    path.selected,
    path.hovered {
      stroke: #fff !important;
      opacity: 1;
    }
    path {
      mix-blend-mode: screen;
      opacity: 0.15;
      stroke: #fff !important;
    }

    path.bg {
      stroke: $purple !important;
      mix-blend-mode: normal !important;
      opacity: 1;
    }
  }

  .large {
    h3 {
      @include fluid-font-size($fs-semi-medium);
    }
    .subtitle {
      @include fluid-font-size($fs-base);
    }
  }
</style>

<div
  class="container"
  class:large
  class:inverted
  bind:clientWidth
  bind:clientHeight>

  {#if !inverted}
    <h3 class="color-themed-color">{topic.label}</h3>
  {/if}

  <svg
    width="{W}"
    height="{H}"
    viewBox="0 0 {W}
    {H}"
    style="position: {fitInContainer ? 'absolute' : 'static'}; pointer-events:
    all;"
    on:mousemove="{!showChart ? null : moveHandler}"
    on:mouseout="{() => {
      $hoveredItem = localHoveredItemId = $hoveredItemId = null;
    }}">

    {#if clientWidth && clientHeight}
      {#if showChart}
        {#each displayItems as d (d.id)}
          <path
            on:click="{event => {
              $selectedStateId = d.id;
              const yearIndex = Math.floor((17 * event.offsetX) / W);
              onYearSelected(2004 + yearIndex);
            }}"
            style="stroke: {inverted ? '#FFF' : colorMode === 'url(#election-result)' ? d.id : 'url(#electionYearColor)'})
            !important; stroke-width: {SIZE};"
            d="{d.path}"></path>
        {/each}

        {#each hoveredItems as d (d.id)}
          <g style="pointer-events: none">
            <path class="bg" d="{d.path}"></path>
            <path class="hovered" d="{d.path}"></path>
          </g>
        {/each}

        {#each selectedItems as d (d.id)}
          <g style="pointer-events: none">
            <path class="bg" d="{d.path}"></path>
            <path class="selected" d="{d.path}"></path>
            <!-- <path class="fg" d="{d.path}"></path> -->
          </g>
        {/each}
      {/if}
      <g transform="translate(0, {H - 30})">
        <YearAxis {inverted} {years} {electionYears} {W} />
      </g>
    {/if}
  </svg>

</div>
