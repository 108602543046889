// https://observablehq.com/d/14253dc7edcc7a26
const tau = 2 * Math.PI;

export function blurredVertical(context, operation, radius, step = 1) {
  const alpha = context.globalAlpha;
  context.save();
  let w = 0;
  for (let i = -radius; i <= radius; i += step) w += factor(i / radius);
  w = 1.4 / w;
  for (let i = -radius; i <= radius; i += step) {
    context.globalAlpha = alpha * factor(i / radius) * w;
    context.translate(0, i);
    operation();
    context.translate(0, -i);
  }
  context.restore();

  function factor(x) {
    return Math.exp(-(x * x));
  }
}

export function blurredCircular(context, operation, radius, step = 0.2) {
  radius /= 2;
  const alpha = context.globalAlpha;
  context.globalAlpha = alpha * step * 1.4;
  for (let a = 0; a < 1; a += step) {
    const x = radius * Math.cos(a * tau),
      y = radius * Math.sin(a * tau);
    context.translate(x, y);
    operation();
    context.translate(-x, -y);
  }
  context.globalAlpha = alpha;
}
