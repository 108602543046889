<script>
  import { find } from 'lodash-es';
  import { getContext } from 'svelte';
  import { scaleLinear } from 'd3';
  import { sineInOut } from 'svelte/easing';
  import { spring, tweened } from 'svelte/motion';

  // Access the context using the 'LayerCake' keyword
  // Grab some helpful functions
  const { data, xScale, yScale, xGet, yGet, rGet, width } = getContext(
    'LayerCake',
  );
  const { hoveredItem, selectedStateId } = getContext('stores');

  $: nodes = $data;

  $: size = Math.max(60, $width / 10);

  $: selectedState = find(nodes, x => x.id === $selectedStateId);
</script>

<style lang="scss">
  .label {
    @include fluid-font-size($fs-small);
    @include bold;
    position: absolute;
    transition: transform 0.3s ease-in-out;
    pointer-events: none;
    > div {
      transition: transform 1s ease-in-out, color 1s ease-in-out,
        opacity 1s ease-in-out;

      text-align: center;
      border: 2px solid rgba(#fff, 0.5);
      box-shadow: rgba(#000, 0.3) 0 0 10px, inset rgba(#fff, 0.1) 0 0 30px;
      border-radius: 50%;
    }
  }
</style>

<div style="position: relative;">
  {#if selectedState}
    {#each [selectedState] as selectedState}
      <div
        class="label"
        style=" transform:translate({$xGet(selectedState)}px, {$yGet(selectedState)}px)scale(1)">
        <div
          style="transform: translate(-50%, -50%) scale(1); width:{size}px;
          height:{size}px;"></div>
      </div>
    {/each}
  {/if}

</div>
