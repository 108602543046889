import { formatDefaultLocale, formatLocale } from 'd3';

import { monthLabels } from '../config.js';

export const NA_STRING = '—';
export const CURRENCY_FORMAT = '$,.0f';
export const PERCENT_FORMAT = '.1%';
export const INTEGER_FORMAT = ',.0f';
export const FLOAT_FORMAT = ',.1f';
export const MONTH_FORMAT = '02d';

// the basic formatting function sued
const f = formatLocale({
  ...formatDefaultLocale,
  decimal: '.',
  currency: ['', '  €'],
  percent: ' %',
  nan: NA_STRING,
  thousands: ',',
  grouping: [3],
}).format;

// functions based on indicator type
const indicatorFormats = {
  integer: f(INTEGER_FORMAT),
  currency: f(CURRENCY_FORMAT),
  float: f(FLOAT_FORMAT),
  month: x => monthLabels[x], //f(MONTH_FORMAT),
  normalizedValue: x => Math.min(100, Math.max(1, Math.round(x * 100))),
  electionYearTrendScore: v =>
    (v >= 1 ? '+' : '') + ((v - 1) * 100).toFixed(0) + '%',
  // format for anything else
  default: f(INTEGER_FORMAT),
};

export const formatValue = (value, indicatorId) => {
  const formatter =
    indicatorFormats[indicatorId] || indicatorFormats['default'];
  return formatter(value);
};
