<script>
  import Grid from '../components/Grid.svelte';
</script>

<style lang="scss">
  footer {
    background: #fff;
    display: grid;
    align-items: center;
    justify-items: center;
    @include fluid-space(padding, $sp-large);
    @media (max-width: $br-sm) {
      @include fluid-space(padding, $sp-base);
    }
    border-top: 1px solid rgba(#fff, 0.3);
    > div {
      display: grid;
      align-content: center;
    }
    a {
      border-color: lighten($purple, 30) !important;
      @include fluid-font-size($fs-base);
    }
  }

  li {
    @include fluid-space(padding-bottom, $sp-small);
    span {
      display: block;
      float: none;

      opacity: 0.75;
    }
  }

  h3 {
    @include fluid-space(padding-bottom, $sp-small);
  }
  p {
    line-height: 1.8;
  }

  .inverted {
    background: darken($purple, 5);
    color: white;
    h3 {
      color: #fff;
      opacity: 0.75;
    }
  }

  hr {
    border: none;
    border-top: 1px solid rgba(#fff, 0.3);
    @include fluid-space(margin-bottom, $sp-base);
    @include fluid-space(margin-top, $sp-base);
  }
</style>

<footer class="inverted">
  <div class="">
    <Grid container gutter="{60}">
      <Grid lg="{6}" xl="{4}">
        <h3>Background</h3>
        <p>
          <a
            target="_blank"
            href="https://waves-of-attention.truth-and-beauty.net">
            Waves of Interest
          </a>
          is a collaboration between
          <a target="_blank" href="https://newsinitiative.withgoogle.com/">
            Google News initiative
          </a>
          and
          <a target="_blank" href="https://truth-and-beauty.net/">
            Truth & Beauty
          </a>
          , exploring the hidden patterns in Google search data. This project
          illuminates the interests and concerns of Americans in recent election
          years, as seen through the lens of Google Search Interest in the
          United States from 2004 - 2020.
        </p>
      </Grid>
      <Grid lg="{6}" xl="{4}">
        <h3>Data</h3>
        <p>
          This site presents
          <a target="_blank" href="https://trends.google.com">Google Trends</a>
          data on a curated set of political topics, comprising the currently
          most searched political concepts in the United States, topics from
          <a
            target="_blank"
            href="https://www.pewresearch.org/politics/2020/08/13/important-issues-in-the-2020-election/https://www.pewresearch.org/politics/2020/08/13/important-issues-in-the-2020-election/">
            Pew Research Center's
          </a>
          election surveys, as well as a few manual additions to account for
          emerging 2020 issues.
        </p>
        <hr />
        <p>
          Find more information on data selection, media coverage, and embedding
          code in
          <a
            href="https://docs.google.com/document/d/104TCjczWleQ6JyIfU8s3RP31zzCVQSXLKnovEtv9tcs/edit?usp=sharing"
            target="_blank">
            this document.
          </a>
        </p>
      </Grid>
      <Grid lg="{6}" xl="{4}">
        <h3>Team</h3>
        <ul>
          <li>
            <span>Project direction</span>
            <a target="_blank" href="https://simonrogers.net">Simon Rogers</a>
            ,
            <a target="_blank" href="http://albertocairo.com">Alberto Cairo</a>
          </li>
          <li>
            <span>Data</span>
            Becky Pinnington, Jenny Lee
          </li>
          <li>
            <span>Creative direction, data visualization, design</span>
            <a target="_blank" href="https://truth-and-beauty.net">
              Moritz Stefaner
            </a>
          </li>
          <li>
            <span>Research and annotation</span>
            <a target="_blank" href="https://www.destrysibley.com/">
              Destry Maria Sibley
            </a>
          </li>
          <li>
            <span>Data analysis &amp; cartography</span>
            <a target="_blank" href="https://visionscarto.net/">
              Philippe Rivière
            </a>
          </li>
        </ul>
      </Grid>

    </Grid>
  </div>
</footer>
