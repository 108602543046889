import { writable } from 'svelte/store';
import { breakPoints } from '../config.js';
import { find, filter, toPairs, minBy } from 'lodash-es';
import { scaleThreshold } from 'd3';

export default (function () {
  const { subscribe, set } = writable();
  const scale = scaleThreshold()
    .domain(Object.values(breakPoints).slice(1))
    .range(Object.keys(breakPoints));
  const testSize = () => set(scale(window.innerWidth));

  window.onload = testSize;
  window.onresize = testSize;

  return {
    subscribe,
  };
})();
