<script>
  import { derived } from 'svelte/store';
  // Import the getContext function from svelte
  import { getContext } from 'svelte';
  import {
    geoIdentity,
    geoPath,
    interpolateHcl,
    max,
    range,
    scaleLinear,
    scaleSqrt,
    hsl,
  } from 'd3';
  import { isoplethFactory } from '../util/isopleth';
  import { red, purple, blend } from '../util/colors';
  const { data, xScale, yScale, xGet, yGet, zGet, width, height } = getContext(
    'LayerCake',
  );

  export let intensity;

  const projection = geoIdentity();
  const path = geoPath(projection);

  const positions = derived([width, height], () =>
    $data.map(d => [$xGet(d), $yGet(d)]),
  );

  $: values = $data.map($zGet);

  $: mapWidth = 600;
  $: mapHeight = mapWidth / 2;
  $: rescaleX = scaleLinear()
    .domain($xScale.range())
    .range([0, mapWidth]);
  $: rescaleY = scaleLinear()
    .domain($yScale.range())
    .range([mapHeight, 0]);
  $: rescale = rescaleX(1) - rescaleX(0);
  $: projection
    .scale(1 / rescale)
    .translate([rescaleX.invert(0), rescaleY.invert(0)]);

  $: bandwidth = 19 * (mapWidth / 500);
  $: borderradius = 28 * (mapWidth / 500);
  $: steps = Math.ceil(16 * (mapWidth / 500));

  $: isopleth = isoplethFactory(
    $positions.map(d => [rescaleX(d[0]), rescaleY(d[1])]),
    mapWidth,
    mapHeight,
    mapWidth * 0.25,
  ).thresholds(v => {
    const m = max(v);
    return range(0, m, m / (1 + steps));
  });

  $: contours = isopleth(values, bandwidth, borderradius).filter(
    c => c.value > 0,
  );

  $: numContours = contours.length;

  $: colorScale = scaleLinear()
    .domain([
      0,
      (numContours * 0.25) / intensity,
      (numContours * 0.5) / intensity,
      (numContours * 0.75) / intensity,
      (numContours * 0.9) / intensity,
    ])
    //.range([purple, '#8ba5e8', '#9dd6e0', '#F0F099', '#FFF'])
    .range([purple, '#8ba5e8', '#9dd6e0', '#f8f1c8', '#FFF'])
    .interpolate(interpolateHcl)
    .clamp(true);

  $: strokeOpacityScale = scaleLinear()
    .domain([0, (numContours - 1) / intensity])
    .range([0.33, 0.66])
    .clamp(true);
</script>

<style lang="scss">
  circle {
    stroke: $purple;
    fill: $purple;
    fill-opacity: 0.3;
  }
</style>

<g>
  {#each contours as contour, i}
    <path
      style="fill: {colorScale(i)}; stroke: {hsl(colorScale(i)).brighter(i % 3 === 0 ? strokeOpacityScale(i) : 0.3)};
      stroke-width: {(Math.sqrt($width / 500) * i) / contours.length}; "
      d="{path(contour)}"></path>
  {/each}
</g>
