<script>
  import { setContext } from 'svelte';
  import { fade, fly } from 'svelte/transition';
  import { writable } from 'svelte/store';
  import Waypoint from 'svelte-waypoint';
  import { orderBy, find, keyBy, groupBy, mapValues } from 'lodash-es';
  import { mean, sum, median, max } from 'd3';

  import { ExternalLinkIcon } from 'svelte-feather-icons';

  import * as animateScroll from 'svelte-scrollto';

  import breakPoint from './state/BreakPoint.js';
  import { electionYearColors, purple, coolGray, gray } from './util/colors.js';
  import { topicSelection, electionYears } from './config.js';
  import { tileCoords } from './util/tilemap.js';

  import Router from './Router.svelte';

  import TitleCard from './sections/TitleCard.svelte';
  import Footer from './sections/Footer.svelte';

  import SpecificYearSection from './sections/SpecificYearSection.svelte';
  import ElectionYearSection from './sections/ElectionYearSection.svelte';
  import ElectionYearSummaryText from './components/ElectionYearSummaryText.svelte';
  import MapSection from './sections/MapSection.svelte';

  import ShowMoreButton from './components/ShowMoreButton.svelte';
  import ClearButton from './components/ClearButton.svelte';
  import ShareButton from './components/ShareButton.svelte';
  import TooltipView from './components/TooltipView.svelte';

  import {
    statesDataSet,
    yearlyTrendsDataSet,
    monthlyTrendsDataSet,
    topicsDataSet,
    // electionResultsDataSet,
  } from './data/DataStore.js';

  // ui state
  // for tooltip
  const hoveredItemId = writable();
  const hoveredItem = writable();
  // for state / topic selection

  const selectedStateId = writable('US');
  const selectedTopicId = writable();
  const selectedYear = writable(2020);

  // save reference to global stores in context
  setContext('stores', {
    hoveredItem,
    selectedStateId,
    selectedTopicId,
    hoveredItemId,
    selectedYear,
    breakPoint,
  });

  // local ui state

  let headerSticky = true;
  let activeSectionTitle = '';

  let referenceYear = 2020;

  // ready flag for essential dataset
  $: ready =
    topics.length &&
    states.length &&
    yearlyTrendsData.length &&
    monthlyTrendsData.length > 10000; // make sure full dataset is loaded

  // states
  $: states = $statesDataSet;
  $: statesById = keyBy(states, 'id');

  // topics
  $: topics = $topicsDataSet;

  $: visibleTopicIds = keyBy(topics, 'id');

  $: topicsWithMetrics = topics
    .map(topic => ({
      id: topic.id,
      topic,
      monthlyData: geoFilteredMonthlyDataByTopic[topic.id] || [],
      yearlyData: geoFilteredYearlyDataByTopic[topic.id] || [],
    }))
    .map(o => ({
      ...o,
      meanMonthlyScore: mean(o.monthlyData, d => d.value),
      totalMonthlyScore: sum(o.monthlyData, d => d.value),
      electionYearTrendScore:
        median(o.yearlyData.filter(x => x.isPastElectionYear), d => d.value) /
        median(o.yearlyData.filter(x => !x.isPastElectionYear), d => d.value),

      specificYearTrendScore:
        (find(
          topicSelection,
          ([x, y]) => y === referenceYear && x === o.topic.id,
        )
          ? 100
          : 0) +
        mean(
          o.monthlyData.filter(x => x.year === referenceYear),
          d => d.value,
        ) /
          (mean(
            o.yearlyData.filter(
              x => x.year !== referenceYear && x.isPastElectionYear,
            ),
            d => d.value,
          ) +
            mean(
              o.yearlyData.filter(
                x => x.year !== referenceYear && !x.isPastElectionYear,
              ),
              d => d.value,
            ) +
            max(o.yearlyData, d => d.value)),
    }));

  // election years
  $: closestElectionYear =
    $selectedYear < 2020 ? Math.floor($selectedYear / 4) * 4 : 2016;

  // election results
  // $: electionResults = $electionResultsDataSet.filter(
  //   x => x.year == closestElectionYear,
  // );

  // trend value calculations
  $: yearlyTopicMax = mapValues(groupBy($yearlyTrendsDataSet, 'id'), a =>
    max(a, x => x.value),
  );

  // $: monthlyTopicMax = mapValues(groupBy($monthlyTrendsDataSet, 'id'), a =>
  //   max(a, x => x.value),
  // );

  $: yearlyTrendsData = $yearlyTrendsDataSet.map(x => ({
    ...x,
    normalizedValue: x.value / yearlyTopicMax[x.id],
  }));

  $: monthlyTrendsData = $monthlyTrendsDataSet;
  // .map(x => ({
  //   ...x,
  //   normalizedValue: x.value / monthlyTopicMax[x.id],
  // }));

  $: yearlyTopicStateValues = mapValues(groupBy(yearlyTrendsData, 'id'), a =>
    groupBy(a, 'geo'),
  );

  $: geoFilteredMonthlyData = monthlyTrendsData.filter(
    x => x.geo === selectedState.id,
  );

  $: geoFilteredMonthlyDataByTopic = groupBy(geoFilteredMonthlyData, 'id');

  $: geoFilteredYearlyData = yearlyTrendsData.filter(
    x => x.geo === selectedState.id,
  );

  $: geoFilteredYearlyDataByTopic = groupBy(geoFilteredYearlyData, 'id');

  $: specificYearTrendingTopics = orderBy(
    topicsWithMetrics,
    'specificYearTrendScore',
    'desc',
  ).filter(x => x.totalMonthlyScore > 1);

  // derivations

  $: selectedState = statesById[$selectedStateId] || { id: 'US' };

  $: stateSentenceFragment = selectedState.label
    ? ' in ' + selectedState.label
    : ' in the US';

  $: electionYearSectionTitle =
    selectedState.id === 'US'
      ? 'Which search terms trend in US election years — and when?'
      : 'Which search terms trend in presidential election years ' +
        stateSentenceFragment +
        '?';

  $: specificYearSectionTitle =
    referenceYear === 2020
      ? 'How is 2020 different?'
      : 'How was ' + referenceYear + ' different?';

  // export let dataInfoOpen = false;
</script>

<TooltipView item="{$hoveredItem}" />

<main>
  {#if ready}
    <!-- Sepcific Year Topics -->
    <section
      class="color-themed"
      style="--color: {electionYearColors[referenceYear]}; padding: 1em;">

      <SpecificYearSection
        bind:referenceYear
        embedVersion
        {states}
        {stateSentenceFragment}
        {specificYearTrendingTopics}
        {selectedState} />

      <a
        style="color: #999; font-size: .8em; border: none;"
        href="https://waves-of-interest.truth-and-beauty.net"
        target="_main">
        Source: Google Trends / Waves of Interest
      </a>
    </section>

    <!-- Map section-->
  {/if}
</main>

<!-- Footer -->

<!-- <Footer /> -->

<!-- Router needs to be last so initial state is handled correctly in previous views -->
<Router stores="{[selectedStateId]}" />
