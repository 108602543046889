/*
 * A really simple occlusion model: uses only x,y, and fixed distances.
 *
 */
export function occlusion(labels) {
  const ok = [];
  for (const l of labels.reverse()) {
    if (ok.every(z => Math.abs(z.x - l.x) > 60 || Math.abs(z.y - l.y) > 20))
      ok.push(l);
  }
  labels.reverse();
  return ok;
}
