<script>
  import mousePos from '../state/MousePos.js';

  export let minWidth;
  export let maxWidth = 500;
  let windowWidth = 0;

  $: halfWidth = (maxWidth + 20) / 2;
  $: y = $mousePos.y - 10;
  $: x =
    $mousePos.x - halfWidth < 0
      ? halfWidth
      : $mousePos.x + halfWidth > windowWidth
      ? windowWidth - halfWidth
      : $mousePos.x;
</script>

<style lang="scss">
  .container {
    position: absolute;
    z-index: 99999;
    pointer-events: none;
  }
  .tooltip {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 10px;
    padding: 10px;
    background: $c-gray-0;
    color: #fff;

    border-radius: 2px;
    transform: translate(-50%, -5px);
    @include panel-shadow();
  }
</style>

<svelte:window bind:innerWidth="{windowWidth}" />

<div class="container" style="top: {y}px; left: {x}px;">
  <div class="tooltip" style="min-width:{minWidth}; max-width:{maxWidth};">
    <slot />
  </div>
</div>
