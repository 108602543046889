<script>
  import {
    scaleLinear,
    extent,
    scaleSqrt,
    scaleLog,
    mean,
    min,
    max,
    piecewise,
    interpolate,
  } from 'd3';
  import { orderBy, mapValues, find } from 'lodash-es';
  import { ExternalLinkIcon, PlayIcon, PauseIcon } from 'svelte-feather-icons';
  import { getContext } from 'svelte';
  import { LayerCake, Svg, Html } from 'layercake';
  import ShowMoreButton from '../components/ShowMoreButton.svelte';
  import SelectionLens from '../components/SelectionLens.svelte';
  import LineChart from '../components/LineChart.svelte';
  import StatesLayer from '../components/StatesLayer.svelte';
  import IsoLineLayer from '../components/IsoLineLayer.svelte';

  const { selectedStateId } = getContext('stores');

  export let states = [];
  export let topics = [];
  export let coords = [];
  export let yearlyTopicStateValues = {};
  export let localSelectedTopicId = '/m/0g54wr7';
  export let year = 2020;
  export let fullScreen = false;

  $: _selectedTopicId = localSelectedTopicId || (topics.length && topics[0].id);
  $: coordsByID = coords;

  $: topic =
    find(topics, x => x.id === _selectedTopicId) ||
    (topics.length && topics[0]);

  $: topicInterpolators = mapValues(
    yearlyTopicStateValues[_selectedTopicId],
    x => piecewise(interpolate, x.map(d => d.value)),
  );

  $: lineChartValues = yearlyTopicStateValues[topic.id];

  $: selectedTopicScores = mapValues(topicInterpolators, t =>
    t((year - 2004) / (2020 - 2004)),
  );

  $: roundedYearIndex = Math.round(year) - 2004;
  $: currentScores = mapValues(
    yearlyTopicStateValues[_selectedTopicId],
    a => a[roundedYearIndex].normalizedValue,
  );

  $: topicScoreExtent = extent(Object.values(selectedTopicScores));

  $: stateDisplayItems =
    ready &&
    states.map(d => ({
      id: d.id,
      data: d,
      normalizedScore: currentScores[d.id],
      deviationFromBaselineScore: currentScores[d.id] / currentScores['US'],
      x: coordsByID[d.id].x,
      y: coordsByID[d.id].y,
      r: selectedTopicScores[d.id],
      z: selectedTopicScores[d.id] / selectedTopicScores['US'],
    }));

  $: ready = Object.keys(coords).length && states.length && topics.length;

  $: rDomain = [
    min(stateDisplayItems, d => d.r),
    selectedTopicScores['US'],
    max(stateDisplayItems, d => d.r),
  ];
  $: rRange = [0, 0.5, 1];

  $: rScale = scaleSqrt()
    .domain(zDomain)
    .range(zRange);

  $: zDomain = [
    min(stateDisplayItems, d => d.z),
    1,
    max(stateDisplayItems, d => d.z),
  ];

  $: zRange = [0, 1, 2];

  $: zScale = scaleLinear()
    .domain(zDomain)
    .range(zRange);

  const ANI_TIME = 1000;

  let playing;

  const startAnimation = () => {
    playing = !playing;
    let last = performance.now();
    if (year >= 2019.5) year = 2004;
    window.requestAnimationFrame(function play() {
      let now = performance.now();
      year += (now - last) / ANI_TIME;
      last = now;
      if (year >= 2019.9) {
        year = 2020;
        playing = false;
      }
      if (playing) window.requestAnimationFrame(play);
    });
  };

  let clientWidth, clientHeight;
  const aspect = 2;

  $: padding = scaleLinear([200, 600, 2000], [-0.05, 0, 0.15]).clamp(true)(
    clientWidth,
  );

  $: size =
    (1 - padding * 2) *
    Math.min(clientWidth / aspect, Math.max(160, clientHeight));
  $: mapHeight = Math.min(size, 650);
  $: mapWidth = mapHeight * aspect;
  $: xOffset = ((1 - padding * 2) * clientWidth - mapWidth) / 2;
  $: yOffset = Math.max(0, ((1 - padding * 2) * clientHeight - mapHeight) / 2);

  let showTimeline = false;

  $: usYearsScores = yearlyTopicStateValues[_selectedTopicId]['US'];
  $: usMaxScore = max(usYearsScores, d => d.value);
  $: yearIntensity = piecewise(
    interpolate,
    usYearsScores.map(d => d.value / usMaxScore),
  );
  $: intensity = 0.5 + 0.7 * yearIntensity((year - 2004) / (2020 - 2004));

  $: baseURL = (
    window.location.protocol +
    '//' +
    window.location.host +
    '/' +
    window.location.pathname
  ).replace(/\/$/, '');

  $: mapSharingURL =
    location.href
      .replace(location.hash, '')
      .replace('map.html', '')
      .split('?')[0] +
    'map.html' +
    mapSharingHash;

  $: mapSharingHash =
    '?' + ['map', $selectedStateId, Math.floor(year), topic.id].join(',');

  $: if (fullScreen && !playing)
    history.replaceState({}, document.title, mapSharingURL);
</script>

<style lang="scss">
  .grid {
    display: grid;
    grid-template-areas: 'A B' 'C B';
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto;
    width: 100%;
    height: 80vh;
    min-height: 40em;
    @include fluid-space(grid-gap, $sp-base);
    position: relative;
    .header {
      grid-area: A;
      z-index: 5;
      display: flex;
      flex-direction: column;
      pointer-events: none;
      > * {
        color: #fff !important;
        @include text-shadow-strong;
      }
    }

    @media screen and (min-width: $br-lg) and (max-width: $br-xl) {
      .header {
        padding-left: 10vw;
      }
    }

    @media screen and (max-width: $br-sm) {
      grid-template-areas: 'select' 'A' 'C';
      grid-template-rows: auto 1fr auto;
      grid-template-columns: 100%;

      .header {
        h2,
        h4 {
          display: none;
        }
      }
    }

    &.fullScreen {
      height: 100%;
      // min-height: 100%;
      max-height: 100%;
      min-height: 100%;
      h2,
      h4 {
        display: block !important;
      }
    }

    &:not(.fullScreen) {
      header {
        @media screen and (min-width: $br-xl) and (max-width: $br-hd) {
          margin-top: -2em;
        }
      }
      @media screen and (min-width: $br-hd) {
        .map-container {
          margin: -3em 0 0 15vw !important;
        }
      }
    }
  }

  h2 {
    max-width: 100%;
    line-height: 1.4;
  }
  .note {
    opacity: 0.7;
    @include fluid-font-size($fs-small);
  }
  .map-container {
    grid-area: A;

    &:not(.showTimeline) {
      @include fluid-space(margin-top, $sp-large);
      @include fluid-space(margin-bottom, $sp-large);
    }

    overflow: visible;
    svg {
      overflow: visible;
    }
  }

  .topic-select {
    display: none;
    position: absolute;
    // top: -5em;
    top: 0;
    right: 0;
    z-index: 8;
    @media screen and (max-width: $br-lg) {
      display: block;
    }

    @media screen and (max-width: $br-sm) {
      position: static;
      grid-area: select;
      bottom: 0;
      @include fluid-space(padding-right, $sp-xsmall);
    }
  }

  .topic-list {
    grid-area: B;
    max-height: 100%;
    overflow-y: auto;
    @include fluid-font-size($fs-small);
    z-index: 1;

    @include fluid-space(padding-right, $sp-xsmall);
    li {
      @include fluid-space(padding-top, $sp-xxsmall);
      @include fluid-space(padding-bottom, $sp-xxsmall);
      @include fluid-space(padding-left, $sp-xxsmall);
      @include fluid-space(padding-right, $sp-xxsmall);
      border-bottom: 1px solid rgba(#fff, 0.2);
      cursor: pointer;
      transition: background-color 0.3s ease-out;
      background-color: rgba(#fff, 0);
      &:hover {
        background-color: rgba(#fff, 0.15);
      }

      &.active {
        background-color: rgba(#fff, 0.3);
        pointer-events: none;
      }

      @media screen and (max-width: $br-lg) {
        display: none;
      }
    }
  }

  .time-controls {
    grid-area: C;
    .time-chart {
      height: 20vh;
      max-height: 30vmin;
    }
    input {
      width: 100%;
    }
    background: transparent;
  }

  .secondary-link {
    color: #fff;
    margin-left: 1em;
    opacity: 0.6;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
    }

    a {
      border: none;
    }

    @media screen and (max-width: $br-md) {
      margin: 2em -4em 0.5em 0.5em;
      font-size: 0.85em;
    }
  }
</style>

<div class="grid inverted" class:fullScreen>

  <div class="header">
    <h4>Google Search Interest in {Math.floor(year)}</h4>
    <h2>{topic.label}</h2>
    <p class="note">+% indicates percent difference from US Search Interest</p>
  </div>

  <div
    class="map-container"
    bind:clientWidth
    bind:clientHeight
    class:showTimeline>
    {#if ready && clientWidth * clientHeight > 0}
      <LayerCake
        data="{stateDisplayItems}"
        x="x"
        y="y"
        z="z"
        r="r"
        xRange="{({ width, height }) => [xOffset + padding * clientWidth, xOffset + padding * clientWidth + mapWidth]}"
        yRange="{({ width, height }) => [yOffset + padding * clientHeight + mapHeight, yOffset + padding * clientHeight]}"
        {zScale}
        {zDomain}
        {zRange}
        {rDomain}
        {rRange}
        {rScale}>

        <Svg>
          <IsoLineLayer {intensity} />
        </Svg>
        <Html>
          <StatesLayer />
          <SelectionLens />
        </Html>
      </LayerCake>
    {/if}

  </div>

  <ul class="topic-list">
    {#each topics as topic}
      <li
        class:active="{_selectedTopicId === topic.id}"
        on:click="{() => (localSelectedTopicId = localSelectedTopicId === topic.id ? null : topic.id)}">
        {topic.label}
      </li>
    {/each}
  </ul>

  <div class="time-controls">

    <div
      class="time-chart"
      style="position: relative; height: {showTimeline ? null : '20px'}; margin:
      0 10px;">

      <LineChart
        {topic}
        {states}
        fitInContainer
        inverted
        onYearSelected="{clickedYear => {
          year = clickedYear;
        }}"
        stateValues="{lineChartValues || []}" />

    </div>

    <div style="display: flex; flex-direction: column;">

      <input
        style="display: block; flex-grow: 1; position: relative; top: -32px; "
        type="range"
        bind:value="{year}"
        on:mouseup="{() => (year = Math.round(year))}"
        min="2004"
        max="2020"
        step="0.01" />
      <div>

        <div
          style="display: flex; flex-direction: row;"
          class="column-on-mobile">
          <div
            style="flex-grow: 1; display: flex; align-items: baseline;"
            class="column-on-mobile">
            <ShowMoreButton
              inverted
              bind:showMore="{showTimeline}"
              showMoreText="Show timeline chart"
              showLessText="Hide timeline chart" />

            <div class="secondary-link">
              {#if !fullScreen}
                <a href="{mapSharingURL}" target="_map">
                  <ExternalLinkIcon size="16" />
                  <span>Open this map in a new window</span>
                </a>
              {:else}
                <a
                  href="https://waves-of-interest.truth-and-beauty.net"
                  target="_main">
                  <ExternalLinkIcon size="16" />
                  This map is part of the Waves of Interest site.
                </a>
              {/if}
            </div>
          </div>
          {#if playing}
            <button
              class="round-button"
              style="flex-grow: 0;"
              on:click="{startAnimation}">
              <PauseIcon size="24" />

            </button>
          {:else}
            <button
              class="round-button"
              style="flex-grow: 0;"
              on:click="{startAnimation}">
              <div style="position: relative; left: 2px;">
                <PlayIcon size="24" />
              </div>
            </button>
          {/if}
        </div>
      </div>

    </div>
  </div>
  <div class="topic-select">
    <select bind:value="{localSelectedTopicId}">
      {#each orderBy(topics, 'label') as { label, id }}
        <option value="{id}">{label}</option>
      {/each}
    </select>
  </div>
</div>
