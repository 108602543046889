<script>
  import { yearColors } from './../util/colors.js';
  import { extent, scaleLinear } from 'd3';
  export let inverted = false;
  export let years = [];
  export let electionYears = [];
  export let W = 0;
  export let H = 20;

  $: xScale = scaleLinear()
    .domain(extent(years))
    .range([0, W]);
</script>

<style lang="scss">
  .axis {
    pointer-events: none;
  }
  .axis text {
    @include fluid-font-size($fs-small);
    alignment-baseline: hanging;
  }
</style>

<g transform="translate(0, {H / 2})" class="axis">
  <line style="stroke: #CCC; stroke-width: .75;" x2="{W}"></line>
  {#each years as year}
    {#if inverted}
      <rect
        style="fill: var(--color); stroke: none;"
        x="{xScale(year)}"
        y="{-1.5}"
        width="{3}"
        height="{4}"></rect>
    {/if}
  {/each}
  {#each electionYears as year}
    <text
      dx="{xScale(year)}"
      dy="{12}"
      text-anchor="{{ '2004': 'start', '2020': 'end' }[year] || 'middle'}"
      style="fill: {inverted ? '#FFF' : yearColors[year - 2004]}">
      {year}
    </text>
  {/each}
</g>
