<script>
  import Waypoint from 'svelte-waypoint';
  import { fade } from 'svelte/transition';
  import { getContext } from 'svelte';
  import { orderBy, sample, find } from 'lodash-es';
  import HeaderLineChart from '../components/HeaderLineChart.svelte';

  export let topicsWithMetrics;
  export let title;
  export let subtitle;

  export let stateSentenceFragment;

  //const { selectedTopicId } = getContext('stores');

  //$: selectedTopic = find(topicsWithMetrics, x => x.id === $selectedTopicId);

  $: headerTopics = orderBy(topicsWithMetrics, ['totalMonthlyScore'], ['desc'])
    .filter(d => d.monthlyData.length > 0)
    .slice(0, 20);

  let headerTopic;

  let iid;
  $: {
    if (visible && headerTopic) {
      clearTimeout(iid);
      iid = setTimeout(next, 10000);
    }
  }

  $: {
    if (headerTopics.length && !headerTopic) {
      headerTopic = sample(headerTopics);
      clearTimeout(iid);
      iid = setTimeout(next, 10000);
    }
  }

  let visible;

  const next = () => {
    if (visible && !document.hidden) headerTopic = sample(headerTopics);
  };
</script>

<style lang="scss">
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .title-card {
    height: 70vh;
    display: grid;
    align-content: center;
    align-items: center;

    position: relative;
    color: #fff;

    .header {
      margin-top: -1rem;
    }

    h1 {
      line-height: 1.2;
      @include fluid-space(margin-bottom, $sp-xsmall);
    }

    h1,
    .sub-head {
      color: #fff;
      @include text-shadow-strong;
      animation: fade-in 1.5s ease-out;
      animation-delay: 0;
      animation-fill-mode: both;
      text-align: center;
      z-index: 5;
    }

    .sub-head span {
      opacity: 0.75;
    }

    background: linear-gradient(
      45deg,
      darken($purple, 15),
      lighten($purple, 5)
    );
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .chart {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: center;
    }
  }
</style>

<Waypoint
  on:enter="{() => (visible = true)}"
  on:leave="{() => (visible = false)}"
  once="{false}" />

<div class="title-card" on:click="{() => next()}">
  <div class="chart">
    {#if headerTopic}
      <HeaderLineChart
        title="{headerTopic.topic.label}"
        values="{headerTopic.monthlyData}"
        {stateSentenceFragment} />
    {/if}
  </div>
  <div class="header">
    <h1>{title}</h1>
    <div class="sub-head">
      <span>{subtitle}</span>
    </div>
  </div>
  <div class="bottom">
    <slot />
  </div>
</div>
