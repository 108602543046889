<script>
  import { onMount, getContext, onDestroy } from 'svelte';
  import { draw, fade } from 'svelte/transition';
  import { quadOut } from 'svelte/easing';
  import { tweened } from 'svelte/motion';
  import { cubicOut } from 'svelte/easing';
  import {
    scaleBand,
    scaleLinear,
    extent,
    line,
    curveMonotoneX,
    clientPoint /* pointer in D3v6 */,
    sum,
  } from 'd3';
  import { Delaunay } from 'd3-delaunay';
  import {
    range,
    groupBy,
    maxBy,
    orderBy,
    flatten,
    mapValues,
  } from 'lodash-es';
  import { formatValue } from '../util/formatting';
  import {
    yearColors,
    purple,
    blend,
    electionYearColorList,
    primaryElectionYearColorScale,
    secondaryElectionYearColorScale,
    greyYearColorScale,
  } from '../util/colors.js';
  import { occlusion } from '../util/occlusion.js';
  import { monthLabels } from '../config.js';

  export let topic;
  export let values;
  export let highlightElectionYears = false;
  export let referenceYear = null;
  export let showFallOnly = false;
  export let large = false;

  $: ready = values.length && topic && clientWidth > 20 && H;

  export let animate = false;

  const { hoveredItem, selectedTopicId } = getContext('stores');

  let clientWidth;
  $: W = clientWidth;
  $: H = clientWidth * 0.39;
  $: SCALE = Math.max(0.5, Math.min(1.5, W / 500));

  let visibleYear = 2020;
  const ANI_TIME = 500;
  const LAST_ANI_TIME = 1000;

  onMount(() => {
    let iid;
    if (animate) {
      visibleYear = 2003;
      iid = setInterval(() => {
        visibleYear++;
        if (visibleYear === 2020) {
          clearInterval(iid);
        }
      }, ANI_TIME / 2);
    }

    return () => {
      clearInterval(iid);
    };
  });

  $: monthFilteredValues = showFallOnly
    ? values.filter(d => months.indexOf(d.month) > -1)
    : values.slice();

  $: yearlyData = groupBy(monthFilteredValues, 'year');

  $: years = range(2004, 2021);
  $: months = showFallOnly ? range(6, 13) : range(1, 13);

  $: xScale = scaleLinear()
    .domain(extent(months))
    .range([W / 20, W * (1 - 1 / 20)]);

  $: yScale = scaleLinear()
    .domain(extent(monthFilteredValues, d => d.value))
    .range([H, 0]);

  $: colorScale = greyYearColorScale;

  $: electionYearColorScale = highlightElectionYears
    ? secondaryElectionYearColorScale
    : colorScale;

  $: sizeScale = scaleLinear()
    .domain([2004, 2020])
    .range([SCALE * 0.75, SCALE * 1.25]);

  $: path0 = line();

  $: path = y =>
    path0
      .x(d =>
        d.month == 12 && d.year == y - 1
          ? xScale(0.99)
          : d.month == 1 && d.year == y + 1
          ? xScale(12.01)
          : xScale(d.month),
      )
      .y((d, i, e) =>
        d.month == 12 && d.year == y - 1
          ? yScale(d.value * 0.01 + 0.99 * e[i + 1].value)
          : d.month == 1 && d.year == y + 1
          ? yScale(d.value * 0.01 + 0.99 * e[i - 1].value)
          : yScale(d.value),
      )
      .defined(
        d =>
          d.year == y ||
          (d.month == 12 && d.year == y - 1) ||
          (d.month == 1 && d.year == y + 1),
      )
      .curve(curveMonotoneX);

  $: displayItems = years
    .filter(d => visibleYear >= d)
    .map(y => ({
      id: [topic.id, y].join('-'),
      year: y,
      isPastElectionYear: y % 4 === 0,
      monthValues: yearlyData[y].map(x => x.normalizedValue),
      path:
        sum(yearlyData[y], d => d.value) > 0
          ? path(y)(monthFilteredValues)
          : '',
      color: y % 4 === 0 ? electionYearColorScale(y) : colorScale(y),
      size:
        y === referenceYear
          ? 5
          : (y % 4 == 0 ? (highlightElectionYears ? 2.5 : 1.66) : 1) *
            sizeScale(y),
    }));

  $: hoveredDisplayItem = displayItems
    .filter(d => $hoveredItem && d.id === $hoveredItem.id)
    .map(d => ({
      ...d,
      size: Math.max(d.size, 3),
    }));

  $: referenceYearDisplayItem = displayItems.filter(
    d => d.year == referenceYear,
  );

  $: maxValues = highlightElectionYears
    ? flatten(
        Object.values(
          mapValues(
            groupBy(
              monthFilteredValues.filter(
                d => d.isPastElectionYear || d.year === 2020,
              ),
              'year',
            ),
            a => maxBy(a, d => (d.year < 2020 ? d.value : d.month)),
          ),
        ),
      )
    : [];

  $: labeledDataPoints =
    !highlightElectionYears || !maxValues.length
      ? []
      : maxValues.map(v => ({
          id: `max-${v.id}-${v.year}`,
          data: v,
          color:
            v.year % 4 === 0
              ? electionYearColorScale(v.year)
              : colorScale(v.year),
          x: xScale(v.month),
          y: yScale(v.value),
        }));

  $: legendMonths =
    W > 400 || showFallOnly ? months : months.filter((d, i) => i % 2 == 0);

  const delaunayHover = event => {
    const hoverDistance = 25; // pixels
    const sample = 100;
    const parent = event.currentTarget.parentElement;
    const paths = Array.from(parent.querySelectorAll('path')).slice(
      0,
      displayItems.length,
    );
    if (
      !parent.delaunay ||
      parent.delaunay.points.length / 2 < sample * paths.length
    ) {
      const points = paths
        .map(p => {
          const l = p.getTotalLength();
          return range(sample)
            .map(i => l ? p.getPointAtLength((i / (sample - 1)) * l) : {});
        })
        .flat();
      parent.delaunay = Delaunay.from(points, d => d.x, d => d.y);
    }
    const point = clientPoint(parent, event);
    const p_i = parent.delaunay.find(...point);
    const delta = Math.hypot(
      point[0] - parent.delaunay.points[2 * p_i],
      point[1] - parent.delaunay.points[2 * p_i + 1],
    );
    if (delta > hoverDistance) {
      $hoveredItem = null;
    } else {
      const i = Math.floor(p_i / sample);
      const d = displayItems[i];
      if (d) {
        const month = Math.floor((12 * event.offsetX) / clientWidth);
        hoveredItem.set({
          id: d.id,
          // year: d.year,
          value: formatValue(month + 1, 'month') + ' ' + d.year,
          singleValue: true,
          // topic,
          // TODO: show chart-specific value of interest
          // month: month + 1,
          // value: d.monthValues[month],
        });
      }
    }
  };
</script>

<style lang="scss">
  .title {
    z-index: 1;
    position: absolute;
    .subtitle {
      @include fluid-font-size($fs-small);
      @include gray-text;
    }

    &.large {
      h3 {
        @include fluid-font-size($fs-semi-medium);
      }
      .subtitle {
        @include fluid-font-size($fs-base);
        line-height: 1.5;
      }
    }

    &:not(.large) {
      h3 {
        line-height: 1.2;
        margin-top: 0.5em;
      }
    }
  }

  .container {
    margin-top: 2.5em;
    margin-bottom: 20px;

    &.large {
      margin-top: 6em;
    }
  }

  svg {
    overflow: visible;
    display: block;
    z-index: 0;
  }

  path {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    pointer-events: none;

    &:not(.bg) {
      mix-blend-mode: multiply;
    }
    /* stroke: #000; */
  }

  .hovered {
    path {
      stroke: #000 !important;
      &.bg {
        stroke: #fff !important;
      }
    }
  }

  .legend text {
    font-size: 10px;
    fill: #555;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-anchor: middle;
  }

  .legend,
  .reference-year {
    pointer-events: none;
  }

  .labeled-point {
    text {
      pointer-events: none;
      user-select: none;
      text-anchor: middle;
      vertical-align: top;
      @include fluid-font-size($fs-small);
      &.bold {
        font-weight: bold;
      }
    }
  }

  .current {
    stroke: $red !important;
  }
</style>

<div class:large class="title">
  <h3
    class="color-themed-color"
    on:click="{() => ($selectedTopicId = topic.id)}">
    {topic.label}
  </h3>
  <div class="subtitle">
    <slot />
  </div>
</div>

<div class="container" class:large bind:clientWidth>
  <svg
    width="100%"
    height="{H || 0}"
    style="pointer-events: all;"
    on:mousemove="{delaunayHover}"
    on:mouseout="{() => {
      $hoveredItem = null;
    }}">
    
    {#if ready}
      {#each displayItems as d, i (d.id)}
        {#if animate}
          <path
            in:draw="{{ duration: ANI_TIME, easing: t => t }}"
            class:current="{!referenceYear && d.year == 2020}"
            stroke-width="{d.size}"
            style="stroke:{d.color}; opacity:{d.year % 4 == 0 ? (highlightElectionYears ? 1 : 0.75) : 0.5};"
            d="{d.path}"></path>
        {:else}
          <path
            class:current="{!referenceYear && d.year == 2020}"
            stroke-width="{d.size}"
            style="stroke:{d.color}; opacity:{d.year % 4 == 0 ? (highlightElectionYears ? 1 : 0.75) : 0.5};"
            d="{d.path}"></path>
        {/if}
      {/each}
      <!-- reference year -->
      {#each referenceYearDisplayItem as d, i (d.id)}
        <g class="reference-year">
          {#if animate}
            <path
              stroke-width="{d.size * 3}"
              style="stroke:#FFF"
              d="{d.path}"
              class="bg"
              in:draw="{{ duration: LAST_ANI_TIME, delay: 500, easing: quadOut }}"></path>
            <path
              stroke-width="{d.size}"
              class="color-themed-stroke"
              d="{d.path}"
              in:draw="{{ duration: LAST_ANI_TIME, delay: 500, easing: quadOut }}"></path>
          {:else}
            <path
              stroke-width="{d.size * 3}"
              style="stroke:#FFF"
              d="{d.path}"
              class="bg"></path>
            <path
              stroke-width="{d.size}"
              class="color-themed-stroke"
              d="{d.path}"></path>
          {/if}
        </g>
      {/each}

      {#each occlusion(labeledDataPoints.filter(d => d.data.year <= visibleYear)) as d, i (d.data.year)}
        <g
          in:fade
          class="labeled-point"
          transform="translate({d.x}, {d.y - 6})">
          <text
            class:bold="{d.data.year === 2020}"
            style="fill: #FFF; stroke: #FFF; stroke-width: 5px; ">
            {d.data.year}
          </text>
          <text class:bold="{d.data.year === 2020}" style="fill: {d.color}; ">
            {d.data.year}
          </text>
        </g>
      {/each}

      {#each hoveredDisplayItem as d, i (d.id)}
        <g class="hovered">
          <path d="{d.path}" stroke-width="{d.size * 3}" class="bg"></path>
          <path
            d="{d.path}"
            class:current="{!referenceYear && d.year == 2020}"
            stroke-width="{d.size}"></path>
        </g>
      {/each}

      <g transform="translate(0, {H})" class="legend">
        <line
          style="stroke: #000; stroke-opacity: .1; stroke-width: 2"
          x2="{W}"></line>
        {#each legendMonths as month}
          <g transform="translate({xScale(month)}, 15)">
            <text>{monthLabels[month]}</text>
          </g>
        {/each}
      </g>
    {/if}
  </svg>
</div>
