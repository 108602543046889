<script>
  import { onMount, tick } from 'svelte';

  import { get } from 'svelte/store';

  // list of stores to sync with URL state
  export let stores = [];

  async function updateHash() {
    // console.log(stores.map(get));
    // simple encoding <store1>/<store2>/…

    await tick();

    // TODO: might crash iPads? use history.replace()?
    window.location.hash = stores
      .map(x => encodeURIComponent(get(x) == 'undefined' ? '' : get(x)))
      .join('/');
  }

  function parseValue(v) {
    switch (v) {
      case 'false':
        return false;
      case 'true':
        return true;
      default:
        return v;
    }
  }

  function hashchange() {
    const h = window.location.hash
      .slice(1)
      .split('?')[0]
      .split('/');

    // update stores
    h.forEach(
      (value, i) =>
        value !== '' &&
        stores[i] &&
        stores[i].set &&
        stores[i].set(parseValue(decodeURIComponent(value))),
    );
  }

  onMount(() => {
    hashchange();
    // update hash when stores change
    stores.forEach(p => p.subscribe(updateHash));
  });
</script>

<svelte:window on:hashchange="{hashchange}" />
