import { range } from 'lodash-es';

export const breakPoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1280,
  full: 1600,
};

export const modeOptions = [
  {
    id: 'bundles',
    label: 'Line Bundles',
  },
  {
    id: 'monthly',
    label: 'monthly',
  },
  // {
  //   id: 'monthly-year-by-year',
  //   label: 'monthly-year-by-year',
  // },
  // { id: 'tile-map', label: 'Tile Map' },

  {
    id: 'isomap',
    label: 'isomap',
  },
  // {
  //   id: 'time-umap',
  //   label: 'time umap',
  // },
];

export const years = range(2004, 2021);
export const electionYears = [2004, 2008, 2012, 2016, 2020];
export const isElectionYear = y => electionYears.indexOf(+y) > -1;
export const isPastElectionYear = y =>
  Number(y) !== 2020 && electionYears.indexOf(+y) > -1;

export const monthLabels = [
  null,
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const topicSelection = [
  // ['/m/01_942', 2020],
  // ['/m/01vnp9', 2012],
  // ['/m/0273t5w', 2004],
  // ['/m/03l7f', 2020],
  // ['/m/04g_71', 2012],
  // ['/m/04n7dpf', 2020],
  // ['/m/04rsd', 2016],
  // ['/m/07s_c', 2020],
  // ['/m/09jvc1', 2016],
  // ['/m/0dghfp', 2008],
  // ['/m/0dghfp', 2008],
  // ['/m/0gfps3', 2008],
  // ['/m/0h948', 2004],
  // ['/m/06bmj', 2020],
  // ['Trade policy', 2004],
  // ['/m/01tcyp', 2004],
  // ['/m/01phjy', 2016],
  // ['/m/02l3h', 2016],
  // ['/m/04n7qg', 2020],
  // ['/m/05qt0', 2004],
  // ['/m/060c4', 2016],
  // ['/m/07sz1', 2016],
  // ['/m/07wbk', 2016],
  // ['/m/0d075m', 2020],
  // ['/m/0j7l9', 2020],
  // ['/m/0j7l9', 2016],
  // ['/m/0pgkc', 2016],
  // ['/m/0pgkc', 2020],
  // ['/m/09q98q', 2016],
  // ['/m/07jq_', 2004],
  // ['/m/01hy1q', 2004],
  // ['/m/01jwvm', 2004],
  // ['/m/06fhs', 2008],
  // ['/m/082cb', 2004],
  // ['/m/0pd7', 2004],
  // ['/m/02mm_', 2008],
  // ['/m/02gqxg', 2004],
  // ['/m/07__7', 2020],
  // ['/m/0h47fv', 2020],
  // ['/m/02tmvz', 2020],
  // ['/m/06vn82', 2020],
  // ['/m/0m61', 2020],
  // ['/m/015hgk', 2016],
  // ['/m/016hlc', 2004],
  // ['/m/01jgj_', 2004],
  // ['/m/0281cq1', 2004],
  // ['/m/03137q', 2020],
  // ['/m/066w7y', 2012],
  // ['/m/07sgz', 2016],
  // ['/m/07shq', 2016],
  // ['/m/097s4', 2004],
  // ['/m/0g54wr7', 2004],
  // ['/m/02vk53c', 2008],
  // ['/m/04gny', 2004],
  // ['/m/06d4h', 2020],
  // ['/m/06wyc', 2020],
  // ['/m/0c3x5rw', 2012],
  // ['/m/0m9_k', 2020],
  // ['/m/0m9_k', 2016],
  // ['/m/02jfc', 2004],
  // ['/m/01p0f5', 2020],
  // ['/m/05ws7', 2020],
];
