import { flatten, fromPairs } from 'lodash-es';

export const tileGrid = [
  ['AK', '', '', '', '', '', '', '', '', '', '', 'ME'],
  ['', '', '', '', '', '', '', '', '', 'VT', 'NH', ''],
  ['', 'WA', 'MT', 'ND', 'MN', 'WI', '', 'MI', '', 'NY', 'MA', 'RI'],
  ['', 'ID', 'WY', 'SD', 'IA', 'IL', 'IN', 'OH', 'PA', 'NJ', 'CT', ''],
  ['', 'OR', 'NV', 'CO', 'NE', 'MO', 'KY', 'WV', 'VA', 'MD', 'DE', ''],
  ['', 'CA', 'UT', 'NM', 'KS', 'AR', 'TN', 'NC', 'SC', 'DC', '', ''],
  ['', '', '', 'AZ', 'OK', 'LA', 'MS', 'AL', 'GA', '', '', ''],
  ['HI', '', '', '', '', 'TX', '', '', 'FL', '', '', ''],
];

// export const tileCoords = fromPairs(
//   flatten(
//     tileGrid.map((row, i) =>
//       row.map((id, j) => [id, { x: j + 0.5 * (i % 2), y: -i }]),
//     ),
//   ),
// );

export const tileCoords = {
  AK: { x: 0, y: 0 },
  ME: { x: 11, y: 0 },
  VT: { x: 9.5, y: -1 },
  NH: { x: 10.5, y: -1 },
  WA: { x: 1, y: -2 },
  MT: { x: 2, y: -2 },
  ND: { x: 3, y: -2 },
  MN: { x: 4, y: -2 },
  WI: { x: 5, y: -2 },
  MI: { x: 7, y: -2 },
  NY: { x: 9, y: -2 },
  MA: { x: 10, y: -2 },
  RI: { x: 11, y: -2 },
  ID: { x: 1.5, y: -3 },
  WY: { x: 2.5, y: -3 },
  SD: { x: 3.5, y: -3 },
  IA: { x: 4.5, y: -3 },
  IL: { x: 5.5, y: -3 },
  IN: { x: 6.5, y: -3 },
  OH: { x: 7.5, y: -3 },
  PA: { x: 8.5, y: -3 },
  NJ: { x: 9.5, y: -3 },
  CT: { x: 10.5, y: -3 },
  OR: { x: 1, y: -4 },
  NV: { x: 2, y: -4 },
  CO: { x: 3, y: -4 },
  NE: { x: 4, y: -4 },
  MO: { x: 5, y: -4 },
  KY: { x: 6, y: -4 },
  WV: { x: 7, y: -4 },
  MD: { x: 8, y: -4 },
  DE: { x: 9, y: -4 },
  CA: { x: 1.5, y: -5 },
  UT: { x: 2.5, y: -5 },
  NM: { x: 3.5, y: -5 },
  KS: { x: 4.5, y: -5 },
  AR: { x: 5.5, y: -5 },
  TN: { x: 6.5, y: -5 },
  VA: { x: 7.5, y: -5 },
  NC: { x: 8.5, y: -5 },
  DC: { x: 10.5, y: -5 },
  AZ: { x: 3, y: -6 },
  OK: { x: 4, y: -6 },
  LA: { x: 5, y: -6 },
  MS: { x: 6, y: -6 },
  AL: { x: 7, y: -6 },
  GA: { x: 8, y: -6 },
  SC: { x: 9, y: -6 },
  HI: { x: 0.5, y: -7 },
  TX: { x: 5.5, y: -7 },
  FL: { x: 9.5, y: -7 },
};
