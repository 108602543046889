<script>
  import {
    scaleLinear,
    scaleOrdinal,
    max,
    min,
    scaleSqrt,
    interpolateHcl,
  } from 'd3';
  import { fade } from 'svelte/transition';
  import { getContext } from 'svelte';
  import { formatValue } from '../util/formatting.js';

  import {
    ChevronLeftIcon,
    ChevronRightIcon,
    XIcon,
  } from 'svelte-feather-icons';

  import {
    chunk,
    fromPairs,
    orderBy,
    find,
    isNumber,
    isFinite,
  } from 'lodash-es';
  import { electionYears, breakPoints } from './../config.js';

  import ClearButton from '../components/ClearButton.svelte';
  import breakPoint from '../state/BreakPoint.js';
  import Legend from '../components/Legend.svelte';
  import ShowMoreButton from '../components/ShowMoreButton.svelte';
  import Grid from '../components/Grid.svelte';
  import ElectionYearTrendHeader from '../components/ElectionYearTrendHeader.svelte';
  import AnimatedLineChart from '../components/AnimatedLineChart.svelte';

  import {
    secondaryElectionYearColorScale,
    greyYearColorScale,
    red,
  } from '../util/colors.js';

  export let topicsWithMetrics = [];
  export let selectedState = {};
  export let stateSentenceFragment = '';
  let selectedTopicId;

  $: electionYearTrendingTopics = orderBy(
    topicsWithMetrics,
    'electionYearTrendScore',
    'desc',
  ).filter(
    x =>
      x.totalMonthlyScore > 1 &&
      isNumber(x.electionYearTrendScore) &&
      isFinite(x.electionYearTrendScore) &&
      x.electionYearTrendScore > 1.01,
  );

  $: selectedTopic =
    find(topicsWithMetrics, ({ id }) => id === selectedTopicId) ||
    displayedTopics[0];

  $: electionYearColorScale = secondaryElectionYearColorScale;
  let showMore;

  $: numItems = scaleOrdinal()
    .domain(Object.keys(breakPoints))
    .range([4, 6, 8, 8, 12, 12])($breakPoint);

  // $: console.log($breakPoint, numItems, Object.keys(breakPoints));

  $: displayedTopics = electionYearTrendingTopics.slice(0, numItems);

  $: opacityValueScale = scaleSqrt()
    .domain([
      Math.max(1, min(displayedTopics, d => d.electionYearTrendScore)),
      max(displayedTopics, d => d.electionYearTrendScore),
    ])
    .clamp(true)
    .range([0.7, 1]);

  $: buttonColor = scaleLinear()
    .domain([0, topicsWithMetrics.length - 1])
    .range(['#a9478f', '#5924af'])
    .interpolate(interpolateHcl);
</script>

<style lang="scss">
  .value-legend {
    > div {
      @include fluid-space(padding-left, $sp-xsmall);
      @include fluid-space(padding-right, $sp-xsmall);
    }
    display: flex;
  }

  .slide-content {
    height: auto;
  }

  .selected-topic {
    width: 100%;
    @include large-chart-width;
    @include fluid-space(padding, $sp-small);
    @include fluid-space(margin-top, $sp-medium);
    @include panel-shadow;
    .header {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr auto;
    }
  }
  .placeholder {
    border: 1px dotted #aaa;
    width: 100%;
    height: 100%;
    display: flex;
    height: 20em;
    align-items: center;
    justify-items: center;
    justify-content: center;
    @include gray-text();
  }

  .all-topics {
    @include fluid-space(padding-bottom, $sp-base);

    width: 100%;
    .topics {
      // @include fluid-space(padding, $sp-small);
    }
    .topic {
      display: inline-block;
      padding: 0.3em 0.6em;
      align-content: center;
      justify-content: center;
      text-align: center;
    }
    .active {
      background: var(--color);
      color: #fff;
      transition: background-color 0.3s;
    }

    a {
      border: none;
      color: var(--color);
      text-decoration: none;
      &.active {
        color: #fff;
        background-color: var(--color);
      }
    }
  }

  .legend {
    display: flex;
    width: 100%;
    align-items: baseline;
    @include fluid-space(padding-top, $sp-small);
    @include fluid-space(padding-bottom, $sp-small);
    @include fluid-space(margin-bottom, $sp-small);
    border-bottom: 1px solid $c-gray-4;
    color: $c-gray-1;

    @media (max-width: $br-lg) {
    }

    h3 {
      @include fluid-space(padding-left, $sp-small);
      @include fluid-space(padding-right, $sp-small);
      transition: color 0.5s;
      cursor: pointer;
      &:hover {
        color: var(--color);
      }
      &.active {
        color: var(--color);
        pointer-events: none;
      }
    }

    .description,
    .state-info {
      @include gray-text();
    }
  }
</style>

{#if !showMore}
  <div class="legend">
    <div style="display: flex; flex-grow: 1;">
      <h3 class:active="{true}">Top Rising Terms</h3>

      <div class="hide-on-mobile">
        <span class="description">
          in presidential election years (% change in search interest)
        </span>
        <span class="state-info">{stateSentenceFragment}</span>
      </div>
    </div>
    <div style="flex-grow: 0">
      <h3 on:click="{() => (showMore = true)}">Full topic list</h3>
    </div>
  </div>
{:else}
  <div class="legend">
    <h3 on:click="{() => (showMore = false)}">Top Rising Terms</h3>

    <div style="display: flex; flex-grow: 1; justify-content: flex-end;">

      <span class="description hide-on-mobile">
        All terms we use throughout this site: a selection of the top searched
        political terms.
      </span>
      <h3 class:active="{true}" on:click="{() => (showMore = true)}">
        Full topic list
      </h3>

    </div>
  </div>
{/if}

{#if !showMore}
  <Grid container gutter="{0}">
    <!-- <Grid xs="{12}">
    
  </Grid> -->
    {#each displayedTopics as { topic, monthlyData, electionYearTrendScore, opacity }, i ([selectedState.id, topic.id].toString())}
      <Grid xs="{6}" md="{3}" lg="{3}" xl="{2}">
        <div in:fade="{{ delay: i * 30 }}">

          <ElectionYearTrendHeader
            active="{selectedTopic.id === topic.id}"
            on:click="{() => (selectedTopicId = topic.id)}"
            value="{electionYearTrendScore}"
            opacity="{opacityValueScale(electionYearTrendScore)}"
            {topic} />

        </div>
      </Grid>
    {/each}
  </Grid>
{/if}

{#if showMore}
  <div class="all-topics">
    <div class="topics">
      {#each topicsWithMetrics as topic, i (topic)}
        <a
          class="topic"
          style="--color: {buttonColor(i)}"
          in:fade="{{ duration: Math.sqrt(i) * 30 }}"
          class:active="{selectedTopic.id === topic.id}"
          on:click="{() => (selectedTopicId = topic.id)}">
          {topic.topic.label}
        </a>
      {/each}
    </div>
  </div>
{/if}

<div class="selected-topic">
  {#if selectedTopic}
    {#each [selectedTopic] as { topic, monthlyData, electionYearTrendScore } (topic.id)}
      <AnimatedLineChart
        large
        animate
        highlightElectionYears
        {topic}
        values="{monthlyData}">
        <div style="max-width: 19em;">
          <span class="number">
            {formatValue(electionYearTrendScore, 'electionYearTrendScore')}
          </span>
          search interest {stateSentenceFragment}
          <span style="opacity: 1" class="color-themed-color">
            in&nbsp;past&nbsp;election&nbsp;years
          </span>
          compared to&nbsp;other&nbsp;years
          <!-- and
          <span style="color:{red}">2020</span> -->
        </div>
      </AnimatedLineChart>
    {/each}
  {/if}

</div>
