<script>
  import { monthLabels } from './../config.js';
  import { onMount, getContext } from 'svelte';
  import { fade, fly } from 'svelte/transition';
  import { quadOut, quadInOut } from 'svelte/easing';
  import { tweened } from 'svelte/motion';
  import { Canvas, Layer, t } from "svelte-canvas";

  import { scaleBand, scaleLinear, extent, line, curveMonotoneX, timer } from 'd3';
  import { range, groupBy } from 'lodash-es';

  import { yearColors, purple, blend } from '../util/colors.js';
  import { blurredCircular, blurredVertical } from '../util/blurpath.js';

  export let values = [];
  let displayedValues = [];
  export let title = '';
  export let stateSentenceFragment;

  $: ready = values.length;
  $: window.values = values;
  $: cycles = (`${clientWidth}x${clientHeight}`, 0);
  $: values.length && cycles++;

  const progress = tweened(0, {
    duration: 3000,
    easing: quadInOut,
    delay: 0,
  });

  $: progress
      .set(0, {
        duration: cycles < 2 ? 0 : 1500,
        delay: 0,
      })
      .then(() => {
        cycles;
        displayedValues = window.values.slice();
        progress.set(years.length * 2);
      });

  let clientWidth;
  let clientHeight;
  $: W = Math.max(clientWidth, clientHeight * 2) || 0;
  $: H = clientHeight || 0;
  $: SIZE = Math.min(clientHeight, clientWidth) / 200;

  $: years = range(2004, 2021);
  $: months = range(1, 13);

  $: xScale = scaleLinear()
    .domain([1, 12])
    .range([0, W]);

  $: yScale = scaleLinear()
    .range([H * 0.9, H * 0.1]);

  $: colorScale = scaleLinear()
    .domain([2004, 2012, 2020])
    .range(['#234', '#F99', '#FF9']);

  $: sizeScale = scaleLinear()
    .domain([2004, 2012, 2020])
    .range([SIZE * 3, SIZE, SIZE / 2]);

  $: path = line()
    .x(d => xScale(d.month))
    .y(d => yScale(d.value))
    .curve(curveMonotoneX);

  $: yearlyData = groupBy(values, 'year');

  $: items = (yScale.domain(extent(values, d => d.value)), years.map((y, i) => ({
    id: [y].join('-'),
    year: y,
    path: path(yearlyData[y] || []),
    color: colorScale(y),
    blur: 1,
    size: sizeScale(y),
  })));

  $: displayItems = items
      .slice(0, $progress)
      .map((d, i) => ({ ...d, blur: 0.5 + (Math.min(17, $progress) - i - 1) / 2 }) );

  $: visibleMonthLabels = monthLabels.slice(1, $progress);
 

</script>

<style lang="scss">
  .container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  svg {
    // mix-blend-mode: screen;
    opacity: 1;
    mix-blend-mode: screen;
    overflow: hidden;
  }
  path {
    fill: none;
    mix-blend-mode: screen;
  }

  .title {
    opacity: 0.5;
    @include fluid-space(padding, $sp-small);
    @include fluid-font-size($fs-small);
    top: 0;
    width: 100%;
    text-align: center;
    position: absolute;
    transition: opacity 0.3s;
    &:hover {
      opacity: 7;
    }
    z-index: 2;
  }

  text {
    @include fluid-font-size($fs-small);
  }

  .month-labels {
    position: absolute;

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    opacity: 0.3;
    @include fluid-space(padding-bottom, $sp-small);
    div {
      @include fluid-font-size($fs-small);
      @include small-caps;
    }
  }
</style>

{#if ready}
  {#key title}
  <div class="title" in:fade="{{ delay: 3000 }}" out:fade>
    Monthly search interest {stateSentenceFragment} for “{title}”, 2004-2020
  </div>
  {/key}

  <div class="container" bind:clientWidth bind:clientHeight>
    <div
      style="mix-blend-mode: screen; transform: translate({clientWidth - W}px,
      0); height: 100%">

      <div
        class="month-labels"
        style="left: 0px; bottom: 0; width: {(W-30) * (13 / 12)}px;">

        {#each monthLabels.slice(1) as label, i (label)}
          <div transition:fade="{{ duration: 100, delay: i * 20 }}">
            {label}
          </div>
        {/each}

      </div>

      <div style="position:absolute; top:0px;">
      <Canvas width="{W}" height="{H}">
        <Layer
        render={({ context, width, height }) => {
        const animate = context.animate = (context.animate || [])
          .slice(0, displayItems.length); // truncate

        // a message to you, datastore.js: yield when I animate
        window.animatingAt = performance.now();

        displayItems.forEach(function(d, i) {
          const a = animate[i] || (animate[i] = {});
          if (a.path !== d.path) {
            const p = document.createElementNS("http://www.w3.org/2000/svg", "path");
            p.setAttribute("d", d.path);

            a.rewindpath2d = a.path2d; // copy for rewind
            a.rewindl = a.l;

            a.path = d.path;
            a.path2d = new window.Path2D(d.path);
            a.l = p.getTotalLength();

            a.t0 = $progress;
          };

          let delta = ($progress - a.t0) / 17;
          if (delta < -1) return;

          const path2d = a.rewindpath2d || a.path2d;
          const l = a.rewindl || a.l;
          if (a.rewindpath2d) delta += 1;

          context.lineWidth = d.size;
          context.strokeStyle = d.color;
          context.setLineDash(delta <= 1 ? [delta * l, l] : []);
          blurredVertical(context, () => context.stroke(path2d), d.blur);
          
          const tdelta = a.rewindl ? delta + .2 : 1;
          if (tdelta > 0) {
            context.save();
            context.fillStyle = d.color;
            context.font = '300 12px Roboto';
            context.textAlign = 'center';
            context.translate(
              -(clientWidth - W) / 2 + W / 2 + (Math.min(clientWidth, 1500) / 18) * (d.year - 2012),
              H / 5 + (clientWidth < 1000 && i % 2 ? 20 : 0)
            )
            context.globalAlpha = tdelta; 
            blurredCircular(context, () => context.fillText(d.year, 0, 0), d.blur / 4);
            context.restore();
          }
      });
      }} />
      </Canvas>
      </div>
      <svg width="{W}" height="{H}"></svg>
    </div>
  </div>
{/if}
