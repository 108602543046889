// import App from './App.svelte';
import MapApp from './MapPage.svelte';
import YearApp from './YearPage.svelte';
import App from './Page.svelte';


let app; 
const opt = {
  target: document.body
};

if(document.location.href.indexOf('?map') > -1) {
  app = new MapApp(opt);
} else if(document.location.href.indexOf('?year')>-1){
  app = new YearApp(opt); 
} else {
  app = new App(opt);
};
  
export default app;
