<script>
  import Grid from './Grid.svelte';
</script>

<style lang="scss">
  .legend {
    width: 100%;
    display: flex;
    align-items: center;
    > div {
      @include fluid-space(padding-top, $sp-small);
      @include fluid-space(padding-bottom, $sp-small);
    }

    // border-top: 1px solid #ddd;
    border-bottom: 1px solid $c-gray-4;
    @include fluid-font-size($fs-base);
    color: $c-gray-1;

    background: #fff;

    @media (max-width: $br-md) {
    }

    @media (max-width: $br-lg) {
      flex-direction: column-reverse;
      > div {
        @include fluid-space(padding, $sp-small);
        @include fluid-space(padding-top, $sp-xsmall);
        @include fluid-space(padding-bottom, $sp-xsmall);
      }
    }
  }

  .description {
    flex-grow: 1;
  }

  .controls {
    flex-grow: 0;
  }
</style>

<div class="legend">
  <div class="description">
    <span class="legend-title">
      <slot name="description" />
    </span>
    <slot name="state-info" />
  </div>

  <div class="controls">
    <slot name="controls" />
  </div>
</div>

<!-- to get rid of "default" slot warning -->
{#if false}
  <slot />
{/if}
