<script>
  import { setContext } from 'svelte';
  import { fade, fly } from 'svelte/transition';
  import { writable } from 'svelte/store';
  import Waypoint from 'svelte-waypoint';
  import { orderBy, find, keyBy, groupBy, mapValues } from 'lodash-es';
  import { mean, sum, median, max } from 'd3';

  import * as animateScroll from 'svelte-scrollto';

  import breakPoint from './state/BreakPoint.js';
  import { electionYearColors, purple, coolGray, gray } from './util/colors.js';
  import { topicSelection, electionYears } from './config.js';
  import { tileCoords } from './util/tilemap.js';

  import Router from './Router.svelte';

  import TitleCard from './sections/TitleCard.svelte';
  import Footer from './sections/Footer.svelte';

  import SpecificYearSection from './sections/SpecificYearSection.svelte';
  import ElectionYearSection from './sections/ElectionYearSection.svelte';
  import ElectionYearSummaryText from './components/ElectionYearSummaryText.svelte';
  import MapSection from './sections/MapSection.svelte';

  import ShowMoreButton from './components/ShowMoreButton.svelte';
  import ClearButton from './components/ClearButton.svelte';
  import ShareButton from './components/ShareButton.svelte';
  import TooltipView from './components/TooltipView.svelte';

  import {
    statesDataSet,
    yearlyTrendsDataSet,
    monthlyTrendsDataSet,
    topicsDataSet,
    // electionResultsDataSet,
  } from './data/DataStore.js';

  // ui state
  // for tooltip
  const hoveredItemId = writable();
  const hoveredItem = writable();
  // for state / topic selection

  const selectedStateId = writable('US');
  const selectedTopicId = writable();
  const selectedYear = writable(2020);

  // save reference to global stores in context
  setContext('stores', {
    hoveredItem,
    selectedStateId,
    selectedTopicId,
    hoveredItemId,
    selectedYear,
    breakPoint,
  });

  // local ui state

  let headerSticky = true;
  let activeSectionTitle = '';

  let referenceYear = 2020;

  // ready flag for essential dataset
  $: ready =
    topics.length &&
    states.length &&
    yearlyTrendsData.length &&
    monthlyTrendsData.length;

  // states
  $: states = $statesDataSet;
  $: statesById = keyBy(states, 'id');

  // topics
  $: topics = $topicsDataSet;

  $: visibleTopicIds = keyBy(topics, 'id');

  $: topicsWithMetrics = topics
    .map(topic => ({
      id: topic.id,
      topic,
      monthlyData: geoFilteredMonthlyDataByTopic[topic.id] || [],
      yearlyData: geoFilteredYearlyDataByTopic[topic.id] || [],
    }))
    .map(o => ({
      ...o,
      meanMonthlyScore: mean(o.monthlyData, d => d.value),
      totalMonthlyScore: sum(o.monthlyData, d => d.value),
      electionYearTrendScore:
        median(o.yearlyData.filter(x => x.isPastElectionYear), d => d.value) /
        median(o.yearlyData.filter(x => !x.isPastElectionYear), d => d.value),

      specificYearTrendScore:
        (find(
          topicSelection,
          ([x, y]) => y === referenceYear && x === o.topic.id,
        )
          ? 100
          : 0) +
        mean(
          o.monthlyData.filter(x => x.year === referenceYear),
          d => d.value,
        ) /
          (mean(
            o.yearlyData.filter(
              x => x.year !== referenceYear && x.isPastElectionYear,
            ),
            d => d.value,
          ) +
            mean(
              o.yearlyData.filter(
                x => x.year !== referenceYear && !x.isPastElectionYear,
              ),
              d => d.value,
            ) +
            max(o.yearlyData, d => d.value)),
    }));

  // election years
  $: closestElectionYear =
    $selectedYear < 2020 ? Math.floor($selectedYear / 4) * 4 : 2016;

  // election results
  // $: electionResults = $electionResultsDataSet.filter(
  //   x => x.year == closestElectionYear,
  // );

  // trend value calculations
  $: yearlyTopicMax = mapValues(groupBy($yearlyTrendsDataSet, 'id'), a =>
    max(a, x => x.value),
  );

  // $: monthlyTopicMax = mapValues(groupBy($monthlyTrendsDataSet, 'id'), a =>
  //   max(a, x => x.value),
  // );

  $: yearlyTrendsData = $yearlyTrendsDataSet.map(x => ({
    ...x,
    normalizedValue: x.value / yearlyTopicMax[x.id],
  }));

  $: monthlyTrendsData = $monthlyTrendsDataSet;
  // .map(x => ({
  //   ...x,
  //   normalizedValue: x.value / monthlyTopicMax[x.id],
  // }));

  $: yearlyTopicStateValues = mapValues(groupBy(yearlyTrendsData, 'id'), a =>
    groupBy(a, 'geo'),
  );

  $: geoFilteredMonthlyData = monthlyTrendsData.filter(
    x => x.geo === selectedState.id,
  );

  $: geoFilteredMonthlyDataByTopic = groupBy(geoFilteredMonthlyData, 'id');

  $: geoFilteredYearlyData = yearlyTrendsData.filter(
    x => x.geo === selectedState.id,
  );

  $: geoFilteredYearlyDataByTopic = groupBy(geoFilteredYearlyData, 'id');

  $: specificYearTrendingTopics = orderBy(
    topicsWithMetrics,
    'specificYearTrendScore',
    'desc',
  ).filter(x => x.totalMonthlyScore > 1);

  // derivations

  $: selectedState = statesById[$selectedStateId] || { id: 'US' };

  $: stateSentenceFragment = selectedState.label
    ? ' in ' + selectedState.label
    : ' in the US';

  $: electionYearSectionTitle =
    selectedState.id === 'US'
      ? 'Which search terms trend in US election years — and when?'
      : 'Which search terms trend in presidential election years ' +
        stateSentenceFragment +
        '?';

  $: specificYearSectionTitle =
    referenceYear === 2020
      ? 'How is 2020 different?'
      : 'How was ' + referenceYear + ' different?';

  // export let dataInfoOpen = false;
</script>

<style lang="scss" global>
  header {
    @include fluid-space(padding-left, $sp-small);
    @include fluid-space(padding-right, $sp-small);
    @include fluid-space(padding-top, $sp-xsmall);
    @include fluid-space(padding-bottom, $sp-xsmall);

    align-items: flex-end;
    position: sticky;
    box-shadow: none;

    top: 0;
    z-index: 9;
    border-bottom: 1px #fff solid;
    border-top: 1px #fff solid;

    display: grid;
    grid-template-columns: auto 1fr auto;
    background: #fff;

    transition: border 0.5s, box-shadow 0.5s;
    > div {
      display: flex;
    }

    .section-name {
      display: inline-block;
      padding-bottom: 1px;
      @include fluid-space(padding-left, $sp-small);
      @include gray-text();
    }

    .show-only-when-sticky {
      opacity: 0;
      transition: opacity 1s;
    }
    h1 {
      @include fluid-font-size(0.5);
      color: $purple;
      margin: 0;

      padding: 0;
      opacity: 0;
      transition: opacity 0.5s;
      display: inline-block;
    }

    &.sticky {
      @include panel-shadow();
      border-bottom: 1px #fff $theme-color;
      .show-only-when-sticky {
        opacity: 1;
      }
    }

    .select-container {
      justify-content: flex-end;
      min-height: 2.5em;
    }

    @media (max-width: $br-sm) {
      padding: 0.3em 0.6em 0.3em 0.6em;
      align-items: center;
      h1 {
        font-size: 1em;
        flex-grow: 1;
      }

      select-container {
        flex-grow: 0;
        min-height: auto;
      }
    }

    h3 {
      margin: 0;
      padding: 0;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: $c-white;
    max-width: 100vw;
  }

  section {
    // border-top: 1px solid $c-gray-4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    max-width: 100vw;
    width: 100%;

    @include fluid-space(padding, $sp-medium);
    @media (max-width: $br-sm) {
      @include fluid-space(padding, $sp-base);
    }
    h2 {
      max-width: 40rem;
      line-height: 1.3;
    }
    .header {
      p {
        @include fluid-space(margin-top, $sp-small);
        @include fluid-space(margin-bottom, $sp-base);
      }
    }
  }

  // for waypoint
  .wrapper {
    display: block !important;

    width: 100%;
  }

  .controls {
    display: grid;
    align-items: center;
    justify-items: center;
  }

  a.active,
  span.active {
    background: var(--color);
    color: #fff;
    transition: background-color 0.3s;
    padding: 0.05em 0.1em;
    display: inline-block;
    border-radius: 2px;
  }

  .intro {
    margin-top: -3em;
    @media (max-width: $br-sm) {
      margin-top: 0;
    }

    > div {
      @include paragraph-width;
      > * {
        @include fluid-space(margin-top, $sp-base);
      }
      h2 {
        @include fluid-space(margin-top, $sp-large);
      }
    }
  }

  .hidden {
    visibility: hidden;
  }
  .election-years {
    @include fluid-space(margin-bottom, $sp-xlarge);
  }

  .election-years .header {
    display: grid;
    grid-template-areas: A;
    .year-intro {
      grid-area: A;
      align-self: end;
    }
    @include fluid-space(margin-bottom, $sp-base);
  }

  .map-section {
    .header {
      @include fluid-space(margin-bottom, $sp-large);
    }
  }
</style>

<TooltipView item="{$hoveredItem}" />

<TitleCard
  {topicsWithMetrics}
  {stateSentenceFragment}
  title="{'_TITLE_'}"
  subtitle="{'_SUBTITLE_'}" />

<Waypoint
  threshold="{0}"
  once="{false}"
  on:enter="{() => (headerSticky = false)}"
  on:leave="{() => (headerSticky = true)}" />

{#if ready}
  <header class:sticky="{headerSticky}">

    <h1
      class="show-only-when-sticky"
      on:click="{() => {
        animateScroll.scrollToTop();
      }}">
      {'_TITLE_'}
    </h1>

    <div class="section-name show-only-when-sticky hide-on-mobile">
      {activeSectionTitle}
    </div>

    <div class="select-container">

      <!-- <ShareButton /> -->

      <select
        bind:value="{$selectedStateId}"
        style="visibility: {!headerSticky && $selectedStateId === 'US' ? 'hidden' : 'visible'}">
        <option value="US">US</option>
        <option>----</option>
        {#each orderBy(states, 'label') as { label, id }}
          <option value="{id}">{label}</option>
        {/each}
      </select>

      {#if selectedState.id != 'US'}
        <ClearButton on:click="{() => ($selectedStateId = 'US')}" />
      {/if}

    </div>

  </header>
{/if}

<main>
  {#if ready}
    <section class="intro">
      <div class="text-gradient">

        <p class="large ">
          <span>
            Which topics capture the attention of Americans during US election
            years? And how have our interests shifted from one election cycle to
            the next?
          </span>
        </p>

        <p class="large">
          <span>
            This project investigates these questions with the help of
            <a target="_blank" href="https://trends.google.com">
              Google Search Trends
            </a>
            data.
          </span>
        </p>

        <h2>
          <span>{electionYearSectionTitle}</span>
        </h2>

      </div>

    </section>

    <!-- Election Year Topics -->

    <Waypoint
      threshold="{0}"
      once="{false}"
      offset="{200}"
      on:enter="{() => (activeSectionTitle = electionYearSectionTitle)}" />

    <section class="color-themed" style="--color:#5924b0; padding-top: 0;">
      <ElectionYearSection
        {topicsWithMetrics}
        {selectedState}
        {stateSentenceFragment} />

    </section>

    <!-- Sepcific Year Topics -->
    <section
      class="color-themed election-years"
      style="--color: {electionYearColors[referenceYear]};">
      <div class="header">
        {#each electionYears as year (year)}
          <div
            class="year-intro"
            style="--color: {electionYearColors[year]};"
            class:hidden="{referenceYear !== year}">

            <h4 class="color-themed-color">
              A look at standout terms by election year
            </h4>
            <h2 class="color-themed-color" on:click="{() => (year = 2020)}">
              How {year == 2020 ? 'is' : 'was'}
              <span class:active="{true}">{year}</span>
              different{selectedState.id !== 'US' ? ' in ' + selectedState.label : ''}?
            </h2>

            <ElectionYearSummaryText {year} />
          </div>
        {/each}
      </div>

      <Waypoint
        threshold="{0}"
        offset="{-200}"
        once="{false}"
        on:enter="{() => (activeSectionTitle = specificYearSectionTitle)}" />
      <SpecificYearSection
        bind:referenceYear
        {stateSentenceFragment}
        {specificYearTrendingTopics}
        {selectedState} />

    </section>

    <!-- Map section-->
  {/if}
</main>

<div id="map" style="min-height: 100vh">

  <section
    class="color-themed inverted map-section"
    style="--color: {purple}; padding-right: 1em; overflow: hidden;">
    <div class="header" style="z-index: 8;">
      <h4 class="color-themed-color">A new landscape</h4>
      <h2 class="color-themed-color">
        United — or divided — States of Search?
      </h2>
      <p>
        Next, we look at how search behavior varies between states over the
        years. In this map, we track topic interest to geography.
      </p>
      <p>
        Which topics trend in which states, and when? Might states share more
        common interests than we think? And are individual topics becoming more
        divisive — or less?
      </p>
    </div>

    <Waypoint
      threshold="{0}"
      offset="{0}"
      once="{false}"
      on:enter="{() => (activeSectionTitle = 'United — or divided — States of Search?')}" />

    <Waypoint threshold="{0}" offset="{500}">
      {#if ready}
        <MapSection
          {topics}
          {states}
          {yearlyTopicStateValues}
          coords="{tileCoords}" />
      {/if}
    </Waypoint>
  </section>
</div>

<!-- Footer -->

<Footer />

<!-- Router needs to be last so initial state is handled correctly in previous views -->
<Router stores="{[selectedStateId]}" />
