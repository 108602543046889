// https://observablehq.com/d/9810d84423228327
import { contours, range, scaleLinear } from "d3";
import { Delaunay } from "d3-delaunay";
import { blur } from "array-blur";

export function isoplethFactory(centers, width, height, padding) {
  if (!padding) padding = 0;
  const scale = 0.2;
  const w = Math.round((width + 2 * padding) * scale);
  const h = Math.round((height + 2 * padding) * scale);
  const x = scaleLinear([-padding, width + padding], [0, w]);
  const y = x; // Use scaleLinear([0, height], [0, h]); to change the aspect ratio (usually we wouldn't want that)

  const delaunay = Delaunay.from(centers.map(d => [x(d[0]), y(d[1])]));
  const nn = nnmap(delaunay, w, h);
  const bl = blur().width(w);
  const ct = contours().size([w, h]);

  function rescale(contour) {
    for (const polygon of contour.coordinates) {
      for (const ring of polygon) {
        for (const pt of ring) {
          pt[0] = x.invert(pt[0]);
          pt[1] = y.invert(pt[1]);
        }
      }
    }
    return contour;
  }

  function nnmap(delaunay, width, height) {
    const site = new Uint16Array(width * height),
      distance = new Float32Array(width * height);
    width |= 0;
    height |= 0;

    let n = 0;
    for (let i = 0; i < width; i++) {
      for (let j = 0; j < height; j++) {
        site[i + width * j] = n = delaunay.find(i, j);
        distance[i + width * j] = Math.hypot(
          delaunay.points[2 * n] - i,
          delaunay.points[2 * n + 1] - j
        );
      }
    }
    return { site, distance, width, height };
  }

  function datamap(data, nn, borderradius) {
    return borderradius > 0
      ? Array.from(nn.site, (s, i) => data[s] * (nn.distance[i] < borderradius))
      : Array.from(nn.site, s => data[s]);
  }

  function computecontours(data, bandwidth, borderradius) {
    bl.radius(bandwidth * scale);
    return ct(bl(datamap(data, nn, borderradius * scale))).map(rescale);
  }

  computecontours.thresholds = _ =>
    _ ? (ct.thresholds(_), computecontours) : ct.thresholds();

  return computecontours;
}
