<script>
  export let year;
</script>

<style lang="scss">
  p {
    line-height: 1.7;
    color: $c-gray-1;
  }
  span {
    // @include small-caps();
    // @include bold();
    // color: $c-gray-3;
    // @include condensed();
    // text-decoration: underline;
    // text-decoration-color: var(--color);
    color: var(--color);
    // color: var(--color);
    // opacity: 0.8;
  }
</style>

{#if year == 2004}
  <p>
    The election cycle of 2004 was marked by the <span>wars</span>
    in Iraq and Afghanistan, the prisoner abuse scandal at Abu Ghraib, the
    release of the 9/11 Commission Report, and the ongoing crisis of prisoner
    detention at Guantanamo Bay -- all together leading to increased searches
    for <span>terrorism</span>, <span>national security</span>, <span>foreign policy</span>, and <span>war</span>. Two other major issues of concern during the 2004 campaign cycle: incumbent President George W. Bush’s No Child Left Behind <span>education policy</span>, and the soaring price tag of the <span>US budget deficit</span>.
  </p>
{:else if year == 2008}
  <p>
    By mid-2008, the US economy had fallen into financial crisis, and the
    impending sense of doom showed through Google searches -- for <span>economy</span>, <span>interest rate</span>, and <span>recession</span>
    (all terms that would peak again in early 2020). As the demand for oil
    shrank, the ensuing <span>energy</span>
    crisis led many to wonder whether it was finally time to invest in <span>renewable energy</span>. And by year’s end, <span>pension funds</span> had lost record levels in value. There
    was no question: the <span>economy</span>
    was the issue of the 2008 election.
  </p>
{:else if year == 2012}
  <p>
    Four years after the 2008 financial collapse, America wondered whether
    President Obama and Mitt Romney’s <span>economic</span>
    policy proposals would help or hurt the nation long-term. Could the country
    afford to take on more <span>national debt</span>? Had <span>government spending</span>
    gotten too far out of hand? And would the unprecedented growth of <span>student loan debt</span>
    lead us to a second financial catastrophe?
  </p>

  <p>
    Meanwhile, Maine, Maryland, and Washington put <span class="term">same-sex marriage</span>
    on the ballot; and Washington and Colorado legalized recreational <span class="term">cannabis</span>
    for the first time.
  </p>
{:else if year == 2016}
  <p>
    To many Americans, the 2016 presidential election felt different: on one
    side, we had Hillary Clinton, a woman on the ballot for the first time; on
    the other, we had Donald Trump, a candidate unlike any other in history.
    More than any policy issue, the election became the focus of Google
    searches: Americans searched for <span class="term">opinion polls</span>, the <span class="term">Republican Party</span>, <span class="term">voting</span>, and the <span class="term">election itself</span>. The rising gap in <span class="term">income inequality</span> became one notable search trend, and the <span class="term">Second Amendment</span> popped up in searches after a landmark ruling on gun rights by the Supreme
    Court in June and then, in August, a comment by candidate Trump encouraging
    the assassination of Hillary Clinton by gun violence.
  </p>
{:else if year == 2020}
  <p>
    When it comes to top search topics, 2020 has turned out to be a bit different from other years.
    The advance of COVID-19 led to increased searches for terms like <span class="term">vaccine</span>, <span class="term">sick leave</span>, <span class="term">unemployment</span> and <span class="term">postal voting</span>. And newly heightened concerns over foreign interference in the election, plus the biases of social media, left us searching for <span class="term">misinformation</span> and <span class="term">fact checking</span>.
  </p>
{/if}
