<script>
  export let showMore = false;
  export let showMoreText = 'Show more';
  export let showLessText = 'Show less';
  export let inverted = false;
</script>

<style lang="scss">
  button {
    @include raised-button();
    &:before {
      content: '+ ';
      display: inline-block;
      margin-right: 0.6em;
    }
    &.less:before {
      content: '\2013 ';
    }
  }
</style>

<div class:inverted>
  <button on:click="{() => (showMore = !showMore)}" class:less="{showMore}">
    {!showMore ? showMoreText : showLessText}
  </button>
</div>
