<script>
  import { onMount, getContext, createEventDispatcher } from 'svelte';
  import { draw, fade } from 'svelte/transition';
  import { quadOut } from 'svelte/easing';
  import { tweened } from 'svelte/motion';
  import { cubicOut } from 'svelte/easing';
  import { formatValue } from '../util/formatting.js';
  import {
    scaleBand,
    scaleLinear,
    extent,
    line,
    area,
    curveMonotoneX,
    sum,
  } from 'd3';
  import { range, groupBy, maxBy } from 'lodash-es';

  import {
    yearColors,
    purple,
    blend,
    electionYearColorList,
    primaryElectionYearColorScale,
    secondaryElectionYearColorScale,
    greyYearColorScale,
  } from '../util/colors.js';
  import { monthLabels } from '../config.js';

  export let topic;
  export let active = false;
  export let value = 0;
  export let opacity = 1;

  const dispatch = createEventDispatcher();
  const tweenedValue = tweened(0);
  $: {
    tweenedValue.set(value);
  }
  $: number = formatValue($tweenedValue, 'electionYearTrendScore');
</script>

<style lang="scss">
  .container {
    @include interactive;
    @include fluid-space(padding, $sp-small);
    &:hover {
      background: lighten($purple, 45);
    }

    .number {
      @include fluid-font-size($fs-base);
      color: #281843;
    }
    &.active {
      @include panel-shadow;
      h3,
      .number {
        color: #fff !important;
        opacity: 1 !important;
      }
    }
  }

  h3,
  .subtitle {
    z-index: 1;
  }

  .subtitle {
    @include fluid-font-size($fs-base);
    @include gray-text;
  }
</style>

<div
  class="container"
  class:inverted="{active}"
  class:active
  on:click="{() => dispatch('click')}">
  <h3 style="opacity: {opacity};" class="color-themed-color">{topic.label}</h3>
  <!-- {#if value > 1} -->
  <p class="subtitle">
    <span class:number="{value > 1}">{number}</span>
    {' search interest'}
  </p>
  <!-- {/if} -->
</div>
