<script>
  import { onMount, getContext } from 'svelte';
  import { formatValue } from '../util/formatting';
  import { json } from 'd3';

  import Tooltip from './Tooltip.svelte';

  export let item;

  // const LOAD_TRENDING = false;

  // const relatedTermsServiceURL =
  //   'https://clients.truth-and-beauty.net/gni-server?'; //'http://localhost:8000?';

  // const pad = s => String(s).padStart(2, '0');
  // let relatedTermsLookUp = {};
  // let runningRequests = {};

  // $: relatedTermsURL =
  //   item && Object.keys(item).length ? relatedTermsQueryURL(item) : null;

  // const relatedTermsQueryURL = h => {
  //   const stateId = h.state ? h.state.id : selectedState.id;
  //   if (!h.month) {
  //     return `${relatedTermsServiceURL}${h.topic.id}|${
  //       h.year
  //     }-${'01'}|${h.year + 1}-01|${stateId !== 'US' ? 'US-' + stateId : 'US'}`;
  //   } else if (h.month < 12)
  //     return `${relatedTermsServiceURL}${h.topic.id}|${h.year}-${pad(
  //       h.month,
  //     )}|${h.year}-${pad(h.month + 1)}|${
  //       stateId !== 'US' ? 'US-' + stateId : 'US'
  //     }`;
  //   else
  //     return `${relatedTermsServiceURL}${h.topic.id}|${h.year}-${pad(
  //       h.month,
  //     )}|${h.year + 1}-${'01'}|${stateId !== 'US' ? 'US-' + stateId : 'US'}`;
  // };

  // $: {
  //   const url = relatedTermsURL;

  //   if (LOAD_TRENDING && !relatedTermsLookUp[url]) {
  //     relatedTermsLookUp[url] = ['loading...'];
  //     json(url)
  //       .then(result => {
  //         const terms = result.item
  //           ? result.item.map(x => x.title)
  //           : ['no results'];
  //         relatedTermsLookUp[url] = terms;
  //         relatedTermsLookUp = { ...relatedTermsLookUp };
  //       })
  //       .catch(error => {
  //         relatedTermsLookUp[url] = [];
  //         relatedTermsLookUp = { ...relatedTermsLookUp };
  //       });
  //   }
  // }

  // $: relatedTerms = relatedTermsLookUp[relatedTermsURL] || [];
</script>

<style lang="scss">
  @import './styles/theme.scss';
  .row {
    display: flex;
    flex-direction: row;
    min-width: 10em;
  }
  h3 {
    margin: 0;
  }
  .key {
    flex-grow: 0;
    color: $c-gray-2;
    padding: 0 1em 0 0;
    width: 10em;
  }
  .value {
    @include number;
    text-align: right;
    flex-grow: 1;
    font-size: 1.25em;
  }

  .small-value {
    @include number;
    white-space: nowrap;
  }

  .top {
    display: flex;
    flex-direction: row;
  }

  .main {
    display: flex;
    flex-direction: row;
    color: $c-gray-5;
  }

  .top {
    @include fluid-font-size($fs-small);
    color: $c-gray-4;
    > div {
      @include fluid-space(margin-right, $sp-xxsmall);
    }
  }
  .topic {
    @include fluid-font-size($fs-base);
    @include bold();
  }

  .label {
    @include fluid-font-size($fs-small);
    color: $c-gray-4;
  }
  .comment {
    @include fluid-space(margin-top, $sp-xxsmall);
    @include fluid-font-size($fs-small);
    color: $c-gray-4;
  }
</style>

{#if item && Object.keys(item).length}
  <Tooltip minWidth="{item.singleValue ? null : '12em'}">
    {#if item.singleValue}
      <div class="small-value">{item.value}</div>
    {:else}
      <div class="top">
        {#if item.state}
          <div class="state">{item.state.label}</div>
        {/if}
        {#if item.label}
          <div class="label">{item.label}</div>
        {/if}
        {#if item.year}
          <div class="year">
            {#if item.month}{formatValue(item.month, 'month')}{' '}{/if}
            {Math.round(item.year)}
          </div>
        {/if}
      </div>
      <div class="main">
        {#if item.topic}
          <div class="topic">{item.topic.label}</div>
        {/if}

      </div>
      {#if item.value}
        <div style="display: flex;align-items: baseline; ">
          <div class="label">Search interest</div>
          <div class="value">{formatValue(item.value, 'normalizedValue')}</div>
        </div>
      {/if}
      {#if item.comment}
        <div class="comment">{item.comment}</div>
      {/if}
    {/if}
    <!-- <div style="display: block;">
      {#each relatedTerms.slice(0, 5) as term}
        <div>{term}</div>
      {/each}
    </div> -->

  </Tooltip>
{/if}
