<script>
  import { setContext } from 'svelte';
  import { fade, fly } from 'svelte/transition';
  import { writable } from 'svelte/store';
  import Waypoint from 'svelte-waypoint';
  import { orderBy, find, keyBy, groupBy, mapValues } from 'lodash-es';
  import { mean, sum, median, max } from 'd3';

  import * as animateScroll from 'svelte-scrollto';

  import breakPoint from './state/BreakPoint.js';
  import { electionYearColors, purple, coolGray, gray } from './util/colors.js';
  import { topicSelection, electionYears } from './config.js';
  import { tileCoords } from './util/tilemap.js';

  import Router from './Router.svelte';

  import TitleCard from './sections/TitleCard.svelte';
  import Footer from './sections/Footer.svelte';

  import SpecificYearSection from './sections/SpecificYearSection.svelte';
  import ElectionYearSection from './sections/ElectionYearSection.svelte';
  import ElectionYearSummaryText from './components/ElectionYearSummaryText.svelte';
  import MapSection from './sections/MapSection.svelte';

  import ShowMoreButton from './components/ShowMoreButton.svelte';
  import ClearButton from './components/ClearButton.svelte';
  import ShareButton from './components/ShareButton.svelte';
  import TooltipView from './components/TooltipView.svelte';

  import {
    statesDataSet,
    yearlyTrendsDataSet,
    topicsDataSet,
  } from './data/DataStore.js';

  // ui state
  // for tooltip
  const hoveredItemId = writable();
  const hoveredItem = writable();
  const selectedStateId = writable();
  const selectedTopicId = writable();
  let deeplinkYear;
  let deeplinkTopic;
  let stateId;
  let command;

  [command, stateId, deeplinkYear, deeplinkTopic] = (
    document.location.href.split('?')[1] + ',,,'
  ).split(',');

  deeplinkTopic = deeplinkTopic || '/m/0g54wr7';
  deeplinkYear = deeplinkYear || 2020;
  $selectedStateId = stateId || 'US';
  // for state / topic selection

  const selectedYear = writable(deeplinkYear || 2020);

  // save reference to global stores in context
  setContext('stores', {
    hoveredItem,
    selectedStateId,
    selectedTopicId,
    hoveredItemId,
    selectedYear,
    breakPoint,
  });

  // local ui state

  let headerSticky = true;
  let activeSectionTitle = '';

  let referenceYear = 2020;

  // ready flag for essential dataset
  $: ready = topics.length && states.length && yearlyTrendsData.length;

  // states
  $: states = $statesDataSet;
  $: statesById = keyBy(states, 'id');

  // topics
  $: topics = $topicsDataSet;

  $: visibleTopicIds = keyBy(topics, 'id');

  // trend value calculations
  $: yearlyTopicMax = mapValues(groupBy($yearlyTrendsDataSet, 'id'), a =>
    max(a, x => x.value),
  );

  $: yearlyTrendsData = $yearlyTrendsDataSet.map(x => ({
    ...x,
    normalizedValue: x.value / yearlyTopicMax[x.id],
  }));

  $: yearlyTopicStateValues = mapValues(groupBy(yearlyTrendsData, 'id'), a =>
    groupBy(a, 'geo'),
  );

  // derivations

  $: selectedState = statesById[$selectedStateId] || { id: 'US' };

  $: stateSentenceFragment = selectedState.label
    ? ' in ' + selectedState.label
    : ' in the US';

  // export let dataInfoOpen = false;
</script>

<style lang="scss">
  main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: $c-white;
    max-width: 100vw;
  }

  section {
    // border-top: 1px solid $c-gray-4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    max-width: 100vw;
    width: 100%;

    @include fluid-space(padding, $sp-medium);

    h2 {
      max-width: 40rem;
      line-height: 1.3;
    }
    .header {
      p {
        @include fluid-space(margin-top, $sp-small);
        @include fluid-space(margin-bottom, $sp-base);
      }
    }
  }

  a.active,
  span.active {
    background: var(--color);
    color: #fff;
    transition: background-color 0.3s;
    padding: 0.05em 0.1em;
    display: inline-block;
    border-radius: 2px;
  }

  .map-section {
    .header {
      @include fluid-space(margin-bottom, $sp-large);
    }
    overflow: hidden;
    margin: 0;
    @include fluid-space(padding, $sp-base);
    // padding: 0;
    > div {
      width: 100%;
      height: 100vh;
      padding-bottom: 5vmin;
    }
  }
</style>

<TooltipView item="{$hoveredItem}" />

<section class="color-themed inverted map-section" style="--color: {purple};">

  {#if ready}
    <div>
      <MapSection
        {topics}
        {states}
        {yearlyTopicStateValues}
        localSelectedTopicId="{deeplinkTopic}"
        fullScreen="{true}"
        year="{deeplinkYear}"
        coords="{tileCoords}" />
    </div>
  {/if}

</section>
